const formatMessage = (error) => {
  if (error.response) {
    // Request made and server responded
    // console.log(error.response.data);
    // console.log(error.response.status);
    // console.log(error.response.headers);

    if (error.response.status === 404) {
      //   notifier.error(
      //     'The requested resource does not exist or has been deleted'
      //   );

      return "The requested resource does not exist or has been deleted";
    }

    if (error.response.status === 401) {
      //   notifier.error('Please login to access this resource');
      return "Please login to access this resource";
    }

    return error?.response?.data?.Message;
  } else if (error.request) {
    // The request was made but no response was received
    // console.log(
    //   'The request was made but no response was received',
    //   error.request
    // );
    return "The request was made but no response was received";
  } else {
    // Something happened in setting up the request that triggered an Error
    // console.log('Error', error.message);
    return error.message;
  }
};

class ErrorHandler {
  constructor(error) {
    this.formatMessage = formatMessage(error);
  }

  getMessage() {
    return this.formatMessage;
  }
}

export default ErrorHandler;
