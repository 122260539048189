<template>
  <div class="step">
    <el-form
      :label-position="position"
      label-width="200px"
      :model="state"
      :rules="rules"
      ref="user"
    >
      <el-form-item label="First Name" class="text-left" prop="firstName">
        <el-input v-model="state.firstName" placeholder="e.g. John"></el-input>
      </el-form-item>
      <el-form-item label="Middle Name">
        <el-input
          v-model="state.middleName"
          placeholder="e.g. Michael"
        ></el-input>
      </el-form-item>
      <el-form-item label="Last Name" prop="lastName">
        <el-input v-model="state.lastName" placeholder="e.g. Doe"></el-input>
      </el-form-item>
      <el-form-item label="Email" prop="username">
        <el-input
          v-model="state.username"
          placeholder="e.g. john.doe@email.com"
        ></el-input>
      </el-form-item>
      <el-form-item label="Password" prop="password">
        <el-input
          v-model="state.password"
          show-password
          placeholder="strong password"
        ></el-input>
      </el-form-item>
      <el-form-item label="Confirm Password" prop="confirmPassword">
        <el-input
          v-model="state.confirmPassword"
          show-password
          placeholder="strong password"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="mt-12 flex justify-between">
      <button
        type="button"
        @click="goToLogin"
        class="focus:outline-none rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-indigo-500"
      >
        Go to Login
      </button>
      <el-button
        type="button"
        :loading="loading"
        @click="next"
        class="focus:outline-none ml-3 rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-indigo-500"
      >
        Next
      </el-button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { defineComponent, reactive, ref } from "vue";
import { useStore, mapGetters } from "vuex";
import { useRouter } from "vue-router";
import { ElNotification } from "element-plus";
import { setTokenFromUser } from "@/services/setTokenFromUser";

axios.defaults.baseURL = process.env.VUE_APP_API_LOCATION;

import ErrorHandler from "@/services/errorHandler";

import { userRules, userForm } from "./validate";

export default defineComponent({
  name: "UserInwizard",
  props: {
    userId: {
      type: Number,
    },
    loginInfo: {},
  },
  setup(_, { emit }) {
    const store = useStore();
    const router = useRouter();

    const position = "left";
    const user = ref(null);
    const loading = ref(false);

    const state = reactive(userForm);
    const rules = userRules;

    const next = () => {
      user.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          axios
            .post(`SystemUsers`, state)
            .then(async (result) => {
              store.dispatch("wizard/setUser", result.data.Item);

              let response = await setTokenFromUser(state);

              store.dispatch("wizard/setToken", response.data.access_token);

              emit("onNextStep", {
                component: "Organization",
                id: 1,
                data: result.data.Item,
                loginInfo: state,
                illustration: "IllustrationTwo",
              });
              loading.value = false;
            })
            .catch((error) => {
              loading.value = false;
              ElNotification({
                title: "Warning",
                message: new ErrorHandler(error).getMessage(),
                type: "warning",
              });
            });
        } else {
          return false;
        }
      });
    };

    const goToLogin = () => {
      router.push({ name: "login" });
    };

    return {
      next,
      user,
      state,
      rules,
      position,
      goToLogin,
      loading,
    };
  },
  computed: {
    ...mapGetters("wizard", {
      setUser: null,
    }),
  },
});
</script>

<style lang="scss" scoped></style>
