<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 1600 1200"
    width="384"
    height="256"
  >
    <defs>
      <clipPath id="clip-path">
        <path
          class="cls-1"
          d="M624.86,452.13c-1.9-4.59,4.44-11.79,15-17.87-14.3-3.56-24.46-10.19-23.44-15.76s12.86-8.19,27.5-6.47c-7.7-9.4-11.09-18.38-7.69-22,4.33-4.63,18.07,1.21,30.68,13,11,10.3,17.5,21.76,16.12,27.59-.77,5.94-10.9,14.36-24.82,20.12C642.2,457.38,627.29,458,624.86,452.13Z"
        />
      </clipPath>
    </defs>
    <title></title>
    <g id="Layer_6" data-name="Layer 6">
      <path
        class="cls-2"
        d="M835,163.92h11.53A164.31,164.31,0,0,1,1010.8,328.23V641.69a0,0,0,0,1,0,0H670.64a0,0,0,0,1,0,0V328.23A164.31,164.31,0,0,1,835,163.92Z"
      />
      <path
        class="cls-3"
        d="M902.48,453V646.57H751.54V453H902.48m4-4H747.54V650.57H906.48V449Z"
      />
      <rect class="cls-3" x="747.54" y="448.99" width="158.94" height="41.67" />
      <path
        class="cls-3"
        d="M746.69,348.33V647.54H655.84V348.33h90.85m4-4H651.84V651.54h98.85V344.33Z"
      />
      <path
        class="cls-3"
        d="M1027.25,287.76V646.57H907.33V287.76h119.92m4-4H903.33V650.57h127.92V283.76Z"
      />
      <path
        class="cls-3"
        d="M955.33,332.7h1.15a16.38,16.38,0,0,1,16.38,16.38V386a0,0,0,0,1,0,0H938.94a0,0,0,0,1,0,0V349.08A16.38,16.38,0,0,1,955.33,332.7Z"
      />
      <line class="cls-4" x1="935.07" y1="361.77" x2="976.74" y2="361.77" />
      <path
        class="cls-5"
        d="M955.33,428.32h1.15a16.38,16.38,0,0,1,16.38,16.38v36.92a0,0,0,0,1,0,0H938.94a0,0,0,0,1,0,0V444.7A16.38,16.38,0,0,1,955.33,428.32Z"
      />
      <line class="cls-4" x1="935.07" y1="457.39" x2="976.74" y2="457.39" />
      <path
        class="cls-5"
        d="M955.33,523.94h1.15a16.38,16.38,0,0,1,16.38,16.38v36.92a0,0,0,0,1,0,0H938.94a0,0,0,0,1,0,0V540.32a16.38,16.38,0,0,1,16.38-16.38Z"
      />
      <line class="cls-4" x1="935.07" y1="553.01" x2="976.74" y2="553.01" />
      <path
        class="cls-6"
        d="M955.33,619.56h1.15a16.38,16.38,0,0,1,16.38,16.38v33.92a0,0,0,0,1,0,0H938.94a0,0,0,0,1,0,0V635.94A16.38,16.38,0,0,1,955.33,619.56Z"
      />
      <rect class="cls-3" x="903.33" y="283.76" width="127.92" height="15.99" />
      <rect class="cls-3" x="651.84" y="336.58" width="98.85" height="15.99" />
      <rect class="cls-5" x="776.13" y="528.46" width="33.92" height="43.61" />
      <line class="cls-4" x1="772.25" y1="547.84" x2="813.93" y2="547.84" />
      <line class="cls-4" x1="793.09" y1="574.01" x2="793.09" y2="547.84" />
      <rect class="cls-3" x="776.13" y="607.22" width="33.92" height="43.61" />
      <line class="cls-4" x1="772.25" y1="626.6" x2="813.93" y2="626.6" />
      <line class="cls-4" x1="793.09" y1="652.77" x2="793.09" y2="626.6" />
      <rect class="cls-6" x="840.09" y="528.46" width="33.92" height="43.61" />
      <line class="cls-4" x1="836.22" y1="547.84" x2="877.89" y2="547.84" />
      <line class="cls-4" x1="857.05" y1="574.01" x2="857.05" y2="547.84" />
      <rect class="cls-5" x="840.09" y="607.22" width="33.92" height="43.61" />
      <line class="cls-4" x1="836.22" y1="626.6" x2="877.89" y2="626.6" />
      <line class="cls-4" x1="857.05" y1="652.77" x2="857.05" y2="626.6" />
      <circle class="cls-5" cx="770.07" cy="257.11" r="25.2" />
      <rect class="cls-5" x="627.03" y="641.85" width="427.38" height="29.07" />
      <path
        class="cls-5"
        d="M847.47,135H834c-106.26,0-192.4,86.14-192.4,192.4V670.92h398.31V327.4C1039.88,221.14,953.73,135,847.47,135ZM826.19,641.85H670.64V399.57H826.19Zm0-271.35H670.64V328.39A164.32,164.32,0,0,1,826.19,164.31ZM1010.8,641.85H855.26V399.57H1010.8Zm0-271.35H855.26V164.31A164.31,164.31,0,0,1,1010.8,328.39Z"
      />
      <circle class="cls-2" cx="313.62" cy="1014.96" r="38.76" />
      <path
        class="cls-7"
        d="M1178.05,699a7.66,7.66,0,0,1-.64,3.11c-3,6.83-15.82,7.84-28.57,2.25-10.68-4.67-17.94-12.49-18.25-18.92h0v85.28h47.49V699Z"
      />
      <path
        class="cls-8"
        d="M1159.79,679.65c-12.75-5.59-25.55-4.58-28.57,2.25a7.8,7.8,0,0,0-.63,3.56c.31,6.43,7.57,14.25,18.25,18.92,12.75,5.59,25.55,4.58,28.57-2.25a7.66,7.66,0,0,0,.64-3.11C1178.08,692.51,1170.73,684.43,1159.79,679.65Z"
      />
      <path
        class="cls-7"
        d="M1214.46,727.13c-.31,6.43-7.57,14.25-18.25,18.93-12.76,5.58-25.55,4.58-28.58-2.26a7.78,7.78,0,0,1-.64-3.1h0v30h47.48V727.13Z"
      />
      <path
        class="cls-8"
        d="M1213.83,723.57c-3-6.83-15.82-7.84-28.58-2.25-10.93,4.79-18.28,12.86-18.26,19.38a7.78,7.78,0,0,0,.64,3.1c3,6.84,15.82,7.84,28.58,2.26,10.68-4.68,17.94-12.5,18.25-18.93A7.8,7.8,0,0,0,1213.83,723.57Z"
      />
      <line class="cls-9" x1="1168.38" y1="770.74" x2="1168.38" y2="741.67" />
      <line class="cls-10" x1="1154.33" y1="673.34" x2="1154.33" y2="689.82" />
      <line class="cls-10" x1="1190.73" y1="715.02" x2="1190.73" y2="732.46" />
      <path
        class="cls-6"
        d="M951,1054h0a7.66,7.66,0,0,1-7.28-10.06l24-73.14a7.68,7.68,0,0,1,7.29-5.27h16.82a7.66,7.66,0,0,1,6.69,11.4l-40.86,73.14A7.65,7.65,0,0,1,951,1054Z"
      />
      <path
        class="cls-6"
        d="M1272.52,1054h0a7.66,7.66,0,0,0,7.28-10.06l-24-73.14a7.68,7.68,0,0,0-7.28-5.27h-16.83a7.67,7.67,0,0,0-6.69,11.4l40.86,73.14A7.67,7.67,0,0,0,1272.52,1054Z"
      />
      <rect class="cls-8" x="915.2" y="770.74" width="394.43" height="225.8" />
      <rect
        class="cls-2"
        x="944.27"
        y="799.81"
        width="336.28"
        height="167.66"
      />
      <line class="cls-11" x1="1112.05" y1="796.91" x2="1112.05" y2="970.38" />
      <circle class="cls-6" cx="1151.68" cy="883.64" r="10.16" />
      <circle class="cls-6" cx="1073.14" cy="883.64" r="10.16" />
      <rect
        class="cls-8"
        x="493.77"
        y="897"
        width="16.47"
        height="142.46"
        transform="translate(-466.22 1470.24) rotate(-90)"
      />
      <path
        class="cls-7"
        d="M596.71,1045.48,561.42,833.73H583a8.72,8.72,0,1,0,0-17.44H421.19a8.72,8.72,0,1,0,0,17.44H442.6l-35.29,211.75a7.32,7.32,0,0,0,7.22,8.52h0a7.31,7.31,0,0,0,7.15-5.76l46.76-214.51h67.14l46.75,214.51a7.33,7.33,0,0,0,7.16,5.76h0A7.32,7.32,0,0,0,596.71,1045.48Z"
      />
      <path
        class="cls-8"
        d="M583,833.73a8.72,8.72,0,1,0,0-17.44H421.19a8.72,8.72,0,1,0,0,17.44H442.6l0,.27h25.82l.06-.27h67.14l.06.27h25.82l0-.27Z"
      />
      <path
        class="cls-12"
        d="M592.59,959.72h103c1.28-21.87,5.85-50.88,9.64-90.07,3.37-34.75,6.27-63.92,6.08-88-.32-40.14-103,8.44-103,8.44,0,8.77,1.77,17.19,1.82,25.35.12,21.2-10.62,39.24-18.29,64.81-11.63,38.77-1.94,79.47-1.94,79.47Z"
      />
      <path
        class="cls-13"
        d="M701.08,1043.06H610.91a5.51,5.51,0,0,0,1.66,10.76l132.69.18c1.17-12.9-6.16-22.61-26.84-37C706.62,1027.23,701.08,1043.06,701.08,1043.06Z"
      />
      <path
        class="cls-8"
        d="M713.77,1013.87a39.07,39.07,0,0,1-10.89-10.54c-7-10.27-8.48-24.1-7.33-43.61H606.29a362.87,362.87,0,0,0,3.15,43.61c2.28,16.37,5.36,27.63,7.83,39.47h-4.69a5.45,5.45,0,0,0-1.67.26h90.17s5.54-15.83,17.34-26Q716.2,1015.49,713.77,1013.87Z"
      />
      <path
        class="cls-10"
        d="M696.63,942.2a117.57,117.57,0,0,1-11.15-34.93c-6.53-45.37-2.66-68.63-21.07-89H610.54"
      />
      <path
        class="cls-12"
        d="M730.22,963.59l.1.24,79.52-37.24c-6.19-18.52-12.66-43.16-23.29-75.41C776.59,821,770,798.45,763.76,781a43.11,43.11,0,0,1-56.31-64.82C665,697,514.23,684.49,514.23,684.49l-85.29-12.6c-4.5,7.44-8.33,19.23-10.41,33h29.8v31.87A24.35,24.35,0,0,1,424,761.05h-1.7c-.4,0-.8,0-1.19,0,8.14,30,29.08,55.27,71.82,55.27h172.5c18.41,20.35,14.54,43.61,21.07,89,5.34,37.13,30.65,64.5,30.65,64.5Z"
      />
      <path
        class="cls-13"
        d="M851.36,1006.24l-79.2,35.92a6.52,6.52,0,0,0,6.54,11.23l120.18-56c-4.71-13-16.82-18-45.51-23l-1.23-.23A81.73,81.73,0,0,0,851.36,1006.24Z"
      />
      <path
        class="cls-8"
        d="M852.14,974.12c-8.26-1.58-14.74-4.33-20.09-8.49-9.86-7.68-15.9-20.18-22.21-39l-79.52,37.24c6.74,15.49,16.2,31.78,25.21,46,7.87,12.38,15.38,23.17,20.43,30.4l-2.87,1.39a6.2,6.2,0,0,0-.93.56l79.2-35.92A81.73,81.73,0,0,1,852.14,974.12Z"
      />
      <path
        class="cls-13"
        d="M738.58,789.15A42.89,42.89,0,0,0,763.76,781c-12.41-34.88-23.17-49.41-55.71-64.55l-.6-.27a43.11,43.11,0,0,0,31.13,72.95Z"
      />
      <path
        class="cls-13"
        d="M424,761.05a24.35,24.35,0,0,0,24.35-24.34V704.84h-29.8c-2.63,17.29-2.48,37.63,2.56,56.18.39,0,.79,0,1.19,0Z"
      />
      <line class="cls-10" x1="663.96" y1="818.71" x2="693.03" y2="818.71" />
      <path class="cls-10" d="M789,510.53s-52.33-60.08-114.16-81.87" />
      <path
        class="cls-1"
        d="M624.86,452.13c-1.9-4.59,4.44-11.79,15-17.87-14.3-3.56-24.46-10.19-23.44-15.76s12.86-8.19,27.5-6.47c-7.7-9.4-11.09-18.38-7.69-22,4.33-4.63,18.07,1.21,30.68,13,11,10.3,17.5,21.76,16.12,27.59-.77,5.94-10.9,14.36-24.82,20.12C642.2,457.38,627.29,458,624.86,452.13Z"
      />
      <g class="cls-14">
        <circle class="cls-6" cx="683.55" cy="431.02" r="18.26" />
      </g>
      <ellipse
        class="cls-6"
        cx="707.84"
        cy="416.4"
        rx="9.69"
        ry="40.73"
        transform="translate(-100.67 272.38) rotate(-20.37)"
      />
      <path class="cls-10" d="M721.13,458.2s7.76,21.32,3.88,43.61" />
      <path
        class="cls-15"
        d="M460.93,665.11,554,667s2.33-3.4,5.83-8.55L450.87,655.8Z"
      />
      <path
        class="cls-8"
        d="M579.62,373.28C563.08,391.89,545.5,396,545.5,396c-11.37-12.4-32-12.4-35.15,5.17C506.92,420.64,531,426,531,426s6.07,25.12-3.24,37.53c-8.22,11-18.78,12.81-40.42,15.23,28.2,47.65,57,43.89,66.59,43.89,23,0,29.48-26.69,31.29-40.45a136.86,136.86,0,0,0-16-4.51l-3.1-18.6s14.47,5.16,26.88-2.07,15.51-20.68,11.37-41.35c-2.45-12.25-2.36-27.4-4.46-40.94C591.72,376.12,579.62,373.28,579.62,373.28Z"
      />
      <path
        class="cls-8"
        d="M751.66,477.1C747.27,459.53,712.9,450,712.9,450s2.06-39.44-6.79-40.7c-6.78-1-13.57,15.51-19.38,47.49-6.5,35.74-2.91,101.75-2.91,101.75s-7.19-6.53-17.39-15.62a12.58,12.58,0,0,1-11,7,12.58,12.58,0,0,1-9.63,20.37,12.58,12.58,0,0,1-9.63,20.37,12.58,12.58,0,0,1-11,20.32c21.23,13.82,50.09,29.94,69.32,29.94C795.27,640.88,754.57,488.73,751.66,477.1Z"
      />
      <path
        class="cls-7"
        d="M637.63,603.79a12.6,12.6,0,0,0-1.49-13.17,12.58,12.58,0,0,0,9.63-20.37,12.58,12.58,0,0,0,9.63-20.37,12.58,12.58,0,0,0,11-7c-17.58-15.66-44.09-38.88-58.2-49.31-6.83-5.05-15.53-8.79-23-11.37C583.44,496,577,522.65,554,522.65c-9.55,0-38.39,3.76-66.59-43.89l-9.06,1c-31.2,3.47-45.4,20.26-53.76,40.32-5.17,12.41-9.31,35.15-9.31,35.15a425.08,425.08,0,0,0-4.94,63l40.57,37.55,108.92,2.69c8.72-12.86,24.72-36.61,30-45.72,6.78-11.63,7.75-21.32,7.75-21.32s11.64,9.1,27.59,19.49A12.58,12.58,0,0,0,637.63,603.79Z"
      />
      <path
        class="cls-6"
        d="M591,350.53c-12.4-15.5-46.52-25.84-73.4-6.2s-24.81,51.69-23.78,64.1c0,0-28.94-9.31-49.62,12.4a52.33,52.33,0,0,0-13.44,47.56s-38.08-1.07-45.49,34.12c-4.21,20,3.65,33.31,12.37,41.55a55.69,55.69,0,0,0,17.61,11.17s4.14-22.74,9.31-35.15c8.36-20.06,22.56-36.85,53.76-40.32l9.06-1c21.64-2.42,32.2-4.26,40.42-15.23C537.1,451.12,531,426,531,426s-24.11-5.36-20.68-24.81c3.11-17.57,23.78-17.57,35.15-5.17,0,0,17.58-4.13,34.12-22.74,0,0,12.1,2.84,20.35,1.45C598.53,365.41,596.05,356.86,591,350.53Z"
      />
      <path
        class="cls-16"
        d="M753.81,490.91c-2.93-1.35-4.68-4.75-4.18-11.25,0,0-6.06,7.6-12.51,4.22s-3.87-10.66-3.87-10.66-5.82,3.88-10.66-1c-4.39-4.39-1-11.63-1-11.63s-6.44,2.63-8.72-1.94c-2.87-5.72.62-11.71,4.4-16.3"
      />
      <path class="cls-17" d="M428.92,469.23a46.36,46.36,0,0,0,5.84,14.65" />
      <line class="cls-10" x1="681.88" y1="559.79" x2="684.31" y2="590" />
      <path class="cls-10" d="M594.18,558c6.33,11.4,6.25,25.08,5.43,33.19" />
      <line class="cls-10" x1="537" y1="590" x2="505.99" y2="590.97" />
      <path class="cls-10" d="M565.59,461a116.2,116.2,0,0,1-12.66-5.87" />
      <line class="cls-10" x1="573.34" y1="489.7" x2="571.65" y2="478.99" />
      <line class="cls-17" x1="491.73" y1="408.82" x2="493.88" y2="426.7" />
      <path class="cls-10" d="M594.18,430.1c-2.19,1.42-9.45,1.89-12.93-4.44" />
      <line class="cls-10" x1="536.03" y1="409.74" x2="526.34" y2="404.9" />
      <path class="cls-10" d="M580.77,405.45c-1.55,2.11-8.86,6.2-14.36,1.53" />
      <path class="cls-10" d="M604.32,402.4a9,9,0,0,1-6.78-1.26" />
      <path
        class="cls-10"
        d="M591.21,406c1.11,2.51,2.18,5.28,1.95,6.34a5.89,5.89,0,0,1-2.18,3.22"
      />
      <path
        class="cls-18"
        d="M530.72,421.45l-13.23,12.48a5.76,5.76,0,0,0,2,9.61l15.64,5.55a5.76,5.76,0,0,0,7.63-6.19l-2.41-18A5.76,5.76,0,0,0,530.72,421.45Z"
      />
      <path
        class="cls-8"
        d="M720.65,424.77s6.78-3.88,12.6-1.94c9,3,7.68,11.38,7.68,11.38S753.55,429.66,756,443c0,0,11.38,2.55,8.82,13.93,0,0,9.21.49,7.38,12.56s-9.88,22.1-14.72,22.1c-5,0-8.53-3.07-7.85-12,0,0-6.06,7.6-12.51,4.22s-3.87-10.66-3.87-10.66-5.82,3.88-10.66-1c-4.39-4.39-1-11.63-1-11.63s-6.44,2.63-8.72-1.94c-4.85-9.69,8.49-20.11,10.66-23.25Z"
      />
      <path class="cls-10" d="M717.77,464.05c6.66-6.11,18-17.64,23.38-29.74" />
      <path class="cls-10" d="M730,478a106.94,106.94,0,0,0,26.55-34" />
      <path class="cls-10" d="M747.13,485.18c3-3.66,13.49-16.81,18.69-27.22" />
      <path
        class="cls-6"
        d="M745.25,707.75H577.41l38.15-120.53A17.53,17.53,0,0,1,632.28,575H774.11a17.53,17.53,0,0,1,16.72,22.82l-31.55,99.67A14.73,14.73,0,0,1,745.25,707.75Z"
      />
      <path
        class="cls-6"
        d="M480,694.18a10.42,10.42,0,0,0,9.93,13.57H745.25a14.72,14.72,0,0,0,14-10.28l1-3.29Z"
      />
      <path
        class="cls-7"
        d="M719.54,631c-3.9-5.34-21.63,8.76-22.93,9.93-.68-1.17-9.9-15.27-16.67-9.93-5.87,4.63,7,25.3,10.47,30.57a1.53,1.53,0,0,0,2.28,0C699,656.25,722.93,635.58,719.54,631Z"
      />
      <ellipse
        class="cls-7"
        cx="700.64"
        cy="626.36"
        rx="8.58"
        ry="2.81"
        transform="translate(-105.72 1109.07) rotate(-72.7)"
      />
      <path
        class="cls-7"
        d="M383.83,598.47a12.61,12.61,0,0,0,13-2.42,12.57,12.57,0,0,0,21,8.18,12.58,12.58,0,0,0,21,8.17,12.56,12.56,0,0,0,15.92,11l15.12-40.61A129.34,129.34,0,0,0,478,534l-1.53-54s-34.89,2.9-55.24,28.1c-10.44,12.93-30.93,47.09-44.06,84.23A12.57,12.57,0,0,0,383.83,598.47Z"
      />
      <path
        class="cls-8"
        d="M446.82,623a12.57,12.57,0,0,1-8-10.6,12.58,12.58,0,0,1-21-8.17,12.57,12.57,0,0,1-21-8.18,12.58,12.58,0,0,1-19.74-3.71c-11.38,32.19-17.24,66.63-6.22,91.42,23.72,53.39,94.91,34.6,111.71,29.66s49.44-21.75,71.19-18.78,46.46,13.84,50.42,0c0,0,13.31-.34,9.12-13,0,0,6.69-.83,7.68-5.77,2-9.89-21.75-18.78-21.75-18.78s18.79,3,22.74-1c5-5,.14-13.07-30.55-20.51-33.71-8.16-78.2-3.22-144.44,8.64l7.75-20.83A12.47,12.47,0,0,1,446.82,623Z"
      />
      <line class="cls-10" x1="470.13" y1="577.4" x2="448" y2="642" />
      <path class="cls-10" d="M605.2,657.8c-10.74-4.08-28.62-10.34-42.5-12.6" />
      <path
        class="cls-10"
        d="M613.58,681.12c-13.8-6.21-40.16-17.48-54.34-19.6"
      />
      <path class="cls-10" d="M447.14,642.14c-7.44,1.2-19.12,3.19-30.79,5.52" />
      <path
        class="cls-10"
        d="M603.77,696c-10.35-5.27-32.16-15.75-47.49-18.67"
      />
      <path
        class="cls-13"
        d="M1067,568.4s-7.86,17.68-28.48,17.68S1010,568.4,1010,568.4s-58,132.6-1,202.34h58.93C1124.9,701,1067,568.4,1067,568.4Z"
      />
      <path
        class="cls-6"
        d="M1038.47,586.08c20.62,0,28.48-17.68,28.48-17.68h-57S1017.84,586.08,1038.47,586.08Z"
      />
      <path class="cls-19" d="M1091.74,686.45c-32.06.57-70,12.15-99.2,53" />
      <path class="cls-19" d="M987.41,654c21.52-5.25,62.27-10.25,101.77-.85" />
      <path class="cls-19" d="M1089,720.49c-14.34.17-40.85,16.42-37.93,49.17" />
      <path
        class="cls-20"
        d="M1034.86,580.2c-.6-14.25-4.39-70.88-26.89-104.1"
      />
      <ellipse
        class="cls-6"
        cx="990.94"
        cy="513.54"
        rx="10.25"
        ry="45.59"
        transform="translate(45.07 1106.01) rotate(-59.46)"
      />
      <ellipse
        class="cls-6"
        cx="1009.24"
        cy="373.35"
        rx="8.8"
        ry="25.22"
        transform="translate(-52.41 548.97) rotate(-29.69)"
      />
      <path
        class="cls-1"
        d="M947.48,446.63c2.27-5.05,12.95-5.42,25.93-1.55-8-14.31-10.45-27.62-5.16-31.05s16.47,4.16,26.28,17.33c1.73-13.43,6.41-23,12-23.06,7.06,0,12.84,15.57,12.91,34.83a85.63,85.63,0,0,1-1.46,16.42,20.32,20.32,0,0,0-28.78,18.25,86.12,86.12,0,0,1-15.08-5.22C956.49,464.69,944.59,453.08,947.48,446.63Z"
      />
      <path
        class="cls-6"
        d="M998.41,461a20.29,20.29,0,0,1,19.52-1.43c-1.72,8.95-4.83,15.59-8.57,17.75-3.56,2.59-11.1,2.69-20.21.5A20.32,20.32,0,0,1,998.41,461Z"
      />
      <path
        class="cls-20"
        d="M1042.36,579.34c2.76-15.74,17.13-92.91,40.13-136.57"
      />
      <ellipse
        class="cls-6"
        cx="1107.81"
        cy="532.79"
        rx="9.72"
        ry="55.02"
        transform="translate(259.51 1422.45) rotate(-72.05)"
      />
      <path
        class="cls-1"
        d="M1078.16,375.12c5.52-.5,11.08,8.63,14.07,21.84,8.52-14,18.94-22.66,24.53-19.73s4.45,16.39-2.22,31.4c12.56-5.07,23.23-5.71,26-.89,3.48,6.14-7.27,18.83-24,28.33a85.89,85.89,0,0,1-15,6.78,20.33,20.33,0,0,0-30-16.13,87.22,87.22,0,0,1-2.85-15.71C1066.84,391.83,1071.13,375.76,1078.16,375.12Z"
      />
      <path
        class="cls-6"
        d="M1090.63,426.54a20.32,20.32,0,0,1,10.83,16.31c-8.65,2.89-16,3.44-19.68,1.24-4-1.83-7.79-8.36-10.35-17.37A20.3,20.3,0,0,1,1090.63,426.54Z"
      />
      <path
        class="cls-1"
        d="M1137.78,466.67l0,.08a20.34,20.34,0,0,0,12.12,38.67l0,.08c-26.35,8.25-50.43,6.25-53.79-4.48S1111.43,474.92,1137.78,466.67Z"
      />
      <path
        class="cls-2"
        d="M1131.51,500.3a9.87,9.87,0,0,0-5.93-18.77,20.34,20.34,0,0,1,12.22-14.78l12.12,38.67A20.35,20.35,0,0,1,1131.51,500.3Z"
      />
      <path
        class="cls-6"
        d="M1137.21,488.2a9.87,9.87,0,0,1-5.7,12.1,20.34,20.34,0,0,1-5.93-18.77A9.88,9.88,0,0,1,1137.21,488.2Z"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
.cls-1 {
  fill: #4756df;
}
.cls-2 {
  fill: #ffb7d5;
}
.cls-3 {
  fill: #ca7358;
}
.cls-10,
.cls-11,
.cls-15,
.cls-16,
.cls-17,
.cls-19,
.cls-20,
.cls-4,
.cls-9 {
  fill: none;
}
.cls-4 {
  stroke: #ffb7d5;
}
.cls-10,
.cls-16,
.cls-17,
.cls-19,
.cls-20,
.cls-4,
.cls-9 {
  stroke-linecap: round;
}
.cls-10,
.cls-16,
.cls-19,
.cls-4 {
  stroke-linejoin: round;
}
.cls-10,
.cls-11,
.cls-17,
.cls-19,
.cls-20,
.cls-4,
.cls-9 {
  stroke-width: 4px;
}
.cls-5 {
  fill: #f5ebdd;
}
.cls-6 {
  fill: #30242a;
}
.cls-7 {
  fill: #ffbe21;
}
.cls-8 {
  fill: #a15842;
}
.cls-11,
.cls-9 {
  stroke: #a15842;
}
.cls-11,
.cls-17,
.cls-20,
.cls-9 {
  stroke-miterlimit: 10;
}
.cls-10,
.cls-16,
.cls-20 {
  stroke: #30242a;
}
.cls-12 {
  fill: #4856df;
}
.cls-13 {
  fill: #3ec1f3;
}
.cls-14 {
  clip-path: url(#clip-path);
}
.cls-16 {
  stroke-width: 8px;
}
.cls-17 {
  stroke: #000;
}
.cls-18 {
  fill: #f4e6d8;
}
.cls-19 {
  stroke: #f5ebdd;
}
</style>
