export const data = [
  {
    id: 0,
    name: "Create account",
    description: "Verifiable contact information is required for registration",
    href: "#",
    status: "current",
  },
  {
    id: 1,
    name: "Organization information",
    description:
      "Provide basic information about your business to operate world-wide.",
    href: "#",
    status: "upcoming",
  },
  {
    id: 2,
    name: "Payment setup",
    description: "Subscribe for monthly billing.",
    href: "#",
    status: "upcoming",
  },
];
