<template>
  <nav aria-label="Progress">
    <ol class="overflow-hidden">
      <li
        v-for="(step, stepIdx) in steps"
        :key="step.name"
        :class="[stepIdx !== steps.length - 1 ? 'pb-10' : '', 'relative']"
      >
        <template v-if="step.status === 'complete'">
          <div
            v-if="stepIdx !== steps.length - 1"
            class="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-purple-600"
            aria-hidden="true"
          />
          <a :href="step.href" class="group relative flex items-start">
            <span class="flex h-9 items-center">
              <span
                class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-purple-600 group-hover:bg-purple-800"
              >
                <CheckIcon class="h-5 w-5 text-white" aria-hidden="true" />
              </span>
            </span>
            <span class="ml-4 flex min-w-0 flex-col">
              <span
                class="text-left text-xs font-semibold uppercase tracking-wide"
                >{{ step.name }}</span
              >
              <span class="text-sm text-gray-500">{{ step.description }}</span>
            </span>
          </a>
        </template>
        <template
          v-else-if="step.status === 'current'"
          condition="step.status === 'current'"
        >
          <div
            v-if="stepIdx !== steps.length - 1"
            class="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
            aria-hidden="true"
          />
          <a
            :href="step.href"
            class="group relative flex items-start"
            aria-current="step"
          >
            <span class="flex h-9 items-center" aria-hidden="true">
              <span
                class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-purple-600 bg-white"
              >
                <span class="h-2.5 w-2.5 rounded-full bg-purple-600" />
              </span>
            </span>
            <span class="ml-4 flex min-w-0 flex-col">
              <span
                class="text-left text-xs font-semibold uppercase tracking-wide text-purple-600"
                >{{ step.name }}</span
              >
              <span class="text-sm text-gray-500">{{ step.description }}</span>
            </span>
          </a>
        </template>
        <template v-else>
          <div
            v-if="stepIdx !== steps.length - 1"
            class="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
            aria-hidden="true"
          />
          <a :href="step.href" class="group relative flex items-start">
            <span class="flex h-9 items-center" aria-hidden="true">
              <span
                class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400"
              >
                <span
                  class="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                />
              </span>
            </span>
            <span class="ml-4 flex min-w-0 flex-col">
              <span
                class="text-left text-xs font-semibold uppercase tracking-wide text-gray-500"
                >{{ step.name }}</span
              >
              <span class="text-sm text-gray-500">{{ step.description }}</span>
            </span>
          </a>
        </template>
      </li>
    </ol>
  </nav>
</template>

<script>
import { defineComponent, reactive, watch } from "vue";

import { CheckIcon } from "@heroicons/vue/solid";

import { data } from "./data";

export default defineComponent({
  components: {
    CheckIcon,
  },
  props: {
    currentStep: Number,
  },
  setup(props) {
    let steps = reactive(data);

    watch(
      () => props.currentStep,
      (now, before) => {
        steps[before].status = "upcoming";
        steps[now].status = "current";
      }
    );

    return {
      steps,
    };
  },
});
</script>

<style scoped>
.step {
  margin: 0 auto;
  max-width: 505px;
}
</style>
