<template>
  <div class="step">
    <el-form
      :label-position="position"
      label-width="200px"
      :model="state"
      :rules="rules"
      ref="organization"
    >
      <el-form-item label="Organization Name" prop="name">
        <el-input
          v-model="state.name"
          placeholder="Please input unique Name"
        ></el-input>
      </el-form-item>
      <el-form-item label="Address 1" prop="addressLine1">
        <el-input
          v-model="state.addressLine1"
          placeholder="Please input Address 1"
        ></el-input>
      </el-form-item>
      <el-form-item label="Address 2" prop="addressLine2">
        <el-input
          v-model="state.addressLine2"
          placeholder="Please input Address 2"
        ></el-input>
      </el-form-item>
      <el-form-item label="Zip" prop="zipCode">
        <el-input
          v-model.number="state.zipCode"
          placeholder="Please input Zip"
          maxlength="5"
        ></el-input>
      </el-form-item>
      <el-form-item label="City" prop="city">
        <el-input
          v-model="state.city"
          placeholder="Please input City"
        ></el-input>
      </el-form-item>
      <el-form-item label="State" prop="state">
        <el-select
          v-model="state.state"
          clearable
          filterable
          placeholder="Please select State"
          style="width: 100%"
        >
          <el-option
            v-for="item in USstates"
            :key="item.name"
            :label="item.name"
            :value="item.abbreviation"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Country" prop="country">
        <el-input
          v-model="state.country"
          disabled="disabled"
          placeholder="Please input Country"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="mt-12 flex justify-between">
      <button
        type="button"
        @click="previous"
        class="focus:outline-none rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-indigo-500"
      >
        Go Back
      </button>

      <el-button
        type="button"
        @click="next"
        :loading="loading"
        class="focus:outline-none ml-3 rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-indigo-500"
      >
        Next
      </el-button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { defineComponent, reactive, ref } from "vue";
import { useStore } from "vuex";
import { USstates } from "@/services/USstates";
import { ElNotification } from "element-plus";

import { setTokenFromUser } from "@/services/setTokenFromUser";

import { organizationRules, organizationForm } from "./validate";
import ErrorHandler from "@/services/errorHandler";

axios.defaults.baseURL = process.env.VUE_APP_API_LOCATION;

export default defineComponent({
  name: "OrganizationInwizard",
  props: {
    userId: {
      type: Number,
    },
    loginInfo: Object,
  },
  setup(props, { emit }) {
    const store = useStore();
    const loading = ref(false);

    const position = "left";
    const organization = ref(null);
    const state = reactive(organizationForm);

    const rules = organizationRules;

    const next = () => {
      organization.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          state.systemUserId = props.userId;
          let headers = {
            Authorization: `Bearer ${store.state.wizard.token}`,
          };
          axios
            .post(`Organizations`, state, {
              headers: headers,
            })
            .then((organizationResult) => {
              axios
                .get(
                  `Roles/readOrganizationManager?organizationId=${organizationResult.data.Item.Id}`
                )
                .then((roleResult) => {
                  axios
                    .post(
                      `Users/OrganizationUserAssign?SystemUserId=${state.systemUserId}&OrganizationId=${organizationResult.data.Item.Id}&RoleId=${roleResult.data.Item.Id}`
                    )
                    .then((orgUserResult) => {
                      const orgManagerData = {
                        organizationId: organizationResult.data.Item.Id,
                        organizationUserId: orgUserResult.data.Item.Id,
                      };

                      axios
                        .put(`Organizations/assignAdmin`, orgManagerData)
                        .then(async () => {
                          const user = {
                            username: props.loginInfo.username,
                            password: props.loginInfo.password,
                          };

                          store.dispatch("wizard/removeToken");

                          let response = await setTokenFromUser(user);

                          Promise.all([
                            store.dispatch(
                              "wizard/setToken",
                              response.data.access_token
                            ),
                            store.dispatch(
                              "wizard/setRefreshToken",
                              response.data.refresh_token
                            ),
                          ]).finally(() => {
                            axios
                              .get(`${process.env.VUE_APP_API_LOCATION}Users`, {
                                headers: {
                                  Authorization: `Bearer ${store.state.wizard.token}`,
                                },
                              })
                              .then(async (userResponse) => {
                                /* Set User */
                                store.dispatch(
                                  "wizard/setUser",
                                  userResponse.data.Item
                                );

                                emit("onNextStep", {
                                  component: "Payment",
                                  id: 2,
                                  data: organizationResult.data.Item,
                                  illustration: "IllustrationThree",
                                });
                              });
                          });
                        });
                    });
                })
                .catch((error) => {
                  loading.value = false;
                  ElNotification({
                    title: "Warning",
                    message: new ErrorHandler(error).getMessage(),
                    type: "warning",
                  });
                });
            })
            .catch((error) => {
              loading.value = false;
              ElNotification({
                title: "Warning",
                message: new ErrorHandler(error).getMessage(),
                type: "warning",
              });
            });
        } else {
          return false;
        }
      });
    };

    const previous = () => {
      emit("onPreviousStep", {
        component: "User",
        id: 0,
      });
    };

    return {
      next,
      rules,
      state,
      loading,
      previous,
      position,
      organization,
      USstates,
    };
  },
});
</script>
