<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="384"
    height="256"
    viewBox="0 0 1600 1200"
  >
    <defs></defs>
    <title></title>
    <g id="Layer_9" data-name="Layer 9">
      <rect
        class="cls-1"
        x="1054.53"
        y="870.77"
        width="16.85"
        height="151.26"
        transform="translate(116.56 2009.36) rotate(-90)"
      />
      <path
        class="cls-1"
        d="M1163.5,1015.73l-39.34-234.18h-122.4l-39.34,234.18a7.77,7.77,0,0,0,7.66,9h0a7.75,7.75,0,0,0,7.59-6.11l51.51-237.12h67.55l51.51,237.12a7.77,7.77,0,0,0,7.6,6.11h0A7.77,7.77,0,0,0,1163.5,1015.73Z"
      />
      <rect
        class="cls-2"
        x="1179.84"
        y="870.77"
        width="16.85"
        height="151.26"
        transform="translate(241.87 2134.67) rotate(-90)"
      />
      <path
        class="cls-2"
        d="M1288.81,1015.73l-39.34-234.18h-122.4l-39.35,234.18a7.78,7.78,0,0,0,7.67,9h0a7.75,7.75,0,0,0,7.59-6.11l51.51-237.12H1222l51.51,237.12a7.77,7.77,0,0,0,7.6,6.11h0A7.77,7.77,0,0,0,1288.81,1015.73Z"
      />
      <path
        class="cls-3"
        d="M1165.18,743.8l15-58.68h-99.45a87.82,87.82,0,0,0-85.07,66L985.5,790.92h119A62.63,62.63,0,0,0,1165.18,743.8Z"
      />
      <path
        class="cls-3"
        d="M1222.83,790.92H985.5l4-15.93h104.18l39.73-155.38a98.37,98.37,0,0,1,95.3-74h0c53.46,0,92.58,50.39,79.34,102.18l-24.55,96A62.63,62.63,0,0,1,1222.83,790.92Z"
      />
      <line class="cls-4" x1="991.96" y1="773.44" x2="1118.22" y2="773.44" />
      <line class="cls-4" x1="1094.14" y1="772.86" x2="1116.26" y2="686.35" />
      <path
        class="cls-1"
        d="M920.08,344.23c-6.63-16.87-18.65-36.37-25.18-30.78-1.58,1.36-2.53,3.67-3,6.64-1.27,8.37,1.4,22,4.84,34.4L886.42,359a7.67,7.67,0,0,1-.26.92c-1.89,5.58-8.1,6.15-8.1,6.15s7.29,4.52,4.05,11.93-13.77,5-13.77,5c6.78,7.24,5.85,12.36,1.52,15.83a6.39,6.39,0,0,1-3,1.16c.29,9.12.87,19.56,1.93,30.52l69.32-22C932.39,384.81,925.28,357.46,920.08,344.23Z"
      />
      <path
        class="cls-5"
        d="M895.23,356.48c.66,2.09,1.26,3.87,1.53,4.54,0,0-5.59,13.06-2.8,27.05"
      />
      <path
        class="cls-6"
        d="M869.86,398.87c4.33-3.47,5.26-8.59-1.52-15.83,0,0,10.53,2.38,13.77-5s-4.05-11.93-4.05-11.93,7.74-.68,8.57-8.25"
      />
      <path
        class="cls-7"
        d="M860.9,326.15c4.06,1.22,22.17,1.42,24.7,13.18,1.2,5.54-6.21,7.74-6.21,7.74s8,3.91,7.24,10.76a10,10,0,0,1-.21,1.18l10.34-4.52c-3.44-12.4-6.11-26-4.84-34.4l-12.27-27-13.14-28.93-28,12.73,13.14,28.93,4.32,9.5h0Z"
      />
      <path
        class="cls-1"
        d="M879.39,347.07s7.41-2.2,6.21-7.74c-2.53-11.76-20.64-12-24.7-13.18L856,315.32h0c-.21,0-9.25,1.42-12.9,7.07-5.72,8.86,1.25,15.21,1.25,15.21s-14.1,4.82-6.93,18.12c0,0-8.09,10.12,2,18.21,0,0-7.64,6.82,2.33,16C850,397.55,861,401,866.89,400a6.39,6.39,0,0,0,3-1.16c4.33-3.47,5.26-8.59-1.52-15.83,0,0,10.53,2.38,13.77-5s-4.05-11.93-4.05-11.93,6.21-.57,8.1-6.15a7.67,7.67,0,0,0,.26-.92,10,10,0,0,0,.21-1.18C887.39,351,879.39,347.07,879.39,347.07Z"
      />
      <path class="cls-5" d="M879.88,346.94c-10.47-1-25.28-3.51-36.4-9.47" />
      <path class="cls-5" d="M881,368.2c-10.28-.8-27.46-3.87-42.79-11" />
      <path class="cls-5" d="M873.1,385.82c-6.36-1.39-22.87-5.29-33.82-10.32" />
      <path
        class="cls-8"
        d="M1213.86,501.85a42.2,42.2,0,0,0-.14-4.23l-1.58-8.88c-6.34-22.39-28.41-39.4-36.52-66.17l-27.67.27,0-.52-35.8,16.11-13.9-29.05H1098l-12.84,29.05-14.77-15.7,0,.11s-44.89,1.6-122.3,28.64c0,0-4.32-19.75-9.92-42.89l-69.32,22c4.09,42.15,15.42,91.86,45.66,103,35.44,13.05,121.25-12.13,121.25-12.13l12.12,96.06h159.48V599.77s13.06-6.5,13.06-21.45C1220.39,551.28,1212.92,547.55,1213.86,501.85Z"
      />
      <polygon
        class="cls-9"
        points="1097.99 409.38 1072.12 409.38 1070.38 422.73 1085.15 438.43 1097.99 409.38"
      />
      <path
        class="cls-9"
        d="M1098.3,409.38l13.9,29.05,35.8-16.11c.14-1.48.59-6,1.19-12.39v-.55Z"
      />
      <line class="cls-10" x1="1030.13" y1="491.59" x2="1033.66" y2="520.32" />
      <circle class="cls-11" cx="1097.75" cy="461.28" r="7.93" />
      <circle class="cls-11" cx="890.7" cy="448.22" r="7.93" />
      <line class="cls-10" x1="950.06" y1="451.96" x2="953.65" y2="469.2" />
      <path
        class="cls-1"
        d="M1098,409.38h.31l-.09-.19h51v.19h.07c.42-4.51.93-9.89,1.47-15.7-8.76-2.94-30.65-11.72-38.33-27.94,12.93-4.31,28-22.63,17.24-37.72-14.28-20-38.79,6.47-38.79,6.47s-15.09-9.7-22.63-30.17c-3.23,1.07-12.93,2.15-20.47,0,0,0-3.24,8.62-5.39,23.7q-.45,3.12-1,6s-9.77-.59-10.84,3.72c-1.34,5.33,7.21,11,7.21,11-3.35,12-6.13,21.29-1.83,32.05,3.8,9.49,11.86,20.47,37.72,17.24l-1.45,11.13h25.92Z"
      />
      <path
        class="cls-7"
        d="M1068.21,304.32c7.54,20.47,22.63,30.17,22.63,30.17s24.51-26.46,38.79-6.47c10.77,15.09-4.31,33.41-17.24,37.72,7.68,16.22,29.57,25,38.33,27.94,1.89-20.55,4.17-46.57,4.77-59.19,1-20.57-1-50.74-34.48-65.73-31.25-14-60.34,7.54-60.34,7.54s-26.57-7.65-28,9.7c-1.08,12.93,15.09,18.32,15.09,18.32C1055.28,306.47,1065,305.39,1068.21,304.32Z"
      />
      <path class="cls-12" d="M1074.31,400c5.14-.89,12.34-2.78,18.14-6.76" />
      <path class="cls-12" d="M1046.49,358.7c1.79,1.11,8.46,2,11.17-3.16" />
      <circle class="cls-11" cx="1059.59" cy="330.18" r="3.26" />
      <line class="cls-12" x1="1115.62" y1="340.95" x2="1103.77" y2="348.49" />
      <path
        class="cls-11"
        d="M1047.85,617.49s-80.21,29.85-110,45.7c-51.78,27.51-58.09,42.21-90,139.09C837.19,834.4,830,860.58,823.21,881l86.23,39.41.11-.22,14,6.55s22.59-29.42,26.21-62.72c4.68-43,1.88-72.07,21.53-88.92l227.46-157.64Z"
      />
      <path
        class="cls-1"
        d="M809.3,914.45l18.81,8.77c13,6.08,12,14.87,10.46,18.26a84.11,84.11,0,0,1-4.68,7.77l46.9,22.17a513.73,513.73,0,0,0,28.65-51L823.21,881C818.67,894.73,814.33,905.84,809.3,914.45Z"
      />
      <path
        class="cls-13"
        d="M828.11,923.22l-18.81-8.77c-5,8.51-10.6,14.57-17.77,18.24a40.84,40.84,0,0,1-17.25,4.22c-1.57.06-3.08.16-4.57.26,4,7.16,5.82,18.13,5.82,18.13,11.12,3,24.45-.74,34-4.57,3.6-1.45,6.65-2.92,8.82-4a11.08,11.08,0,0,1,9.79-.18l5.8,2.74a84.11,84.11,0,0,0,4.68-7.77C840.14,938.09,841.16,929.3,828.11,923.22Z"
      />
      <path
        class="cls-11"
        d="M833.89,949.25l-5.8-2.74a11.08,11.08,0,0,0-9.79.18c-2.17,1.12-5.22,2.59-8.82,4-9.5,3.83-22.83,7.58-34,4.57,0,0-1.82-11-5.82-18.13-1,.07-1.89.15-2.81.24C748.76,939.1,738,944,731.6,951.29l122.49,57.1c5.09-6.17,12.29-15.46,20.67-27.83,2-2.89,4-5.92,6-9.14Z"
      />
      <path
        class="cls-13"
        d="M725.66,961.51l120.42,56s3-3.08,8-9.17L731.6,951.29A31.14,31.14,0,0,0,725.66,961.51Z"
      />
      <path
        class="cls-14"
        d="M1141.6,617.49s-98.19,26.9-145.82,56c-49.45,30.17-50,54.77-42.78,135.52"
      />
      <line class="cls-4" x1="1117.97" y1="775.2" x2="1114.25" y2="789.85" />
      <line class="cls-4" x1="1298.48" y1="683.12" x2="1235.99" y2="683.12" />
      <rect
        class="cls-1"
        x="630.58"
        y="870.77"
        width="16.85"
        height="151.26"
        transform="translate(-307.4 1585.41) rotate(-90)"
      />
      <path
        class="cls-1"
        d="M538.46,1015.73,577.8,781.55H700.21l39.34,234.18a7.77,7.77,0,0,1-7.67,9h0a7.77,7.77,0,0,1-7.59-6.11L672.78,781.55H605.23l-51.51,237.12a7.77,7.77,0,0,1-7.59,6.11h0A7.77,7.77,0,0,1,538.46,1015.73Z"
      />
      <rect
        class="cls-2"
        x="505.27"
        y="870.77"
        width="16.85"
        height="151.26"
        transform="translate(-432.71 1460.1) rotate(-90)"
      />
      <path
        class="cls-2"
        d="M413.15,1015.73l39.34-234.18H574.9l39.34,234.18a7.77,7.77,0,0,1-7.67,9h0a7.77,7.77,0,0,1-7.59-6.11L547.47,781.55H479.92l-51.51,237.12a7.77,7.77,0,0,1-7.59,6.11h0A7.77,7.77,0,0,1,413.15,1015.73Z"
      />
      <path
        class="cls-8"
        d="M536.79,743.8l-15-58.68h99.45a87.81,87.81,0,0,1,85.07,66l10.16,39.75h-119A62.62,62.62,0,0,1,536.79,743.8Z"
      />
      <path
        class="cls-8"
        d="M479.13,790.92H716.46l-4-15.93H608.28L568.54,619.61a98.37,98.37,0,0,0-95.3-74h0c-53.46,0-92.58,50.39-79.34,102.18l24.56,96A62.61,62.61,0,0,0,479.13,790.92Z"
      />
      <line class="cls-15" x1="710" y1="773.44" x2="583.74" y2="773.44" />
      <line class="cls-15" x1="607.83" y1="772.86" x2="585.7" y2="686.35" />
      <line class="cls-10" x1="584.48" y1="777.22" x2="587.71" y2="789.85" />
      <line class="cls-10" x1="403.48" y1="683.12" x2="465.97" y2="683.12" />
      <path
        class="cls-7"
        d="M566.48,991.24l-1.26,2.9-341.05,4.19a20.49,20.49,0,0,0-20.49,20.49v6.93H551.56v0h74.61C636.42,1016.42,590.72,999.63,566.48,991.24Z"
      />
      <line class="cls-4" x1="215.81" y1="1024.75" x2="215.81" y2="1017.35" />
      <line class="cls-4" x1="225.13" y1="1024.75" x2="225.13" y2="1017.35" />
      <path
        class="cls-7"
        d="M348.24,1004.33c-4.44-20.42-9.31-84.45-9.77-90.57-.78,0-1.56.06-2.35.11-28.92,1.86-46.64,39.17-51.3,47.56,0,0-17.25,4.58-30.56,10.71a8.56,8.56,0,0,1,4.34,4.77c1.91,5.62-3.12,12.41-11.23,15.16a21,21,0,0,1-7.53,1.15c3.67,20.84,22,32.56,60.84,32.56H368C362.35,1024.45,351.62,1019.88,348.24,1004.33Z"
      />
      <path
        class="cls-7"
        d="M565.76,947.6c-6.77,8.51-30.1,16.17-38.45-3.89-16.88,8.88-56.6,0-53.09-39.55-41,8.1-81.8,33.5-83.92,34.83,11.86,21.12,25,45,27.89,52.29,5.06,12.65,4.78,31.1-7.2,34.5H564.61C573,997.61,574.59,970,565.76,947.6Z"
      />
      <path
        class="cls-11"
        d="M527.31,943.71c8.35,20.06,31.68,12.4,38.45,3.89-7-17.68-20.4-32.15-42.18-41.19-14.49-6-31.93-5.7-49.36-2.25C470.71,943.71,510.43,952.59,527.31,943.71Z"
      />
      <path
        class="cls-11"
        d="M247.37,992.07c8.11-2.75,13.14-9.54,11.23-15.16a8.56,8.56,0,0,0-4.34-4.77c-8.35,3.84-15.14,8.29-15.14,12.61a48.94,48.94,0,0,0,.72,8.47A21,21,0,0,0,247.37,992.07Z"
      />
      <path
        class="cls-4"
        d="M301.61,1024.78v-5.93a20.48,20.48,0,0,1,20.48-20.48H353"
      />
      <line class="cls-4" x1="313.73" y1="1024.78" x2="313.73" y2="1017.39" />
      <line class="cls-4" x1="323.06" y1="1024.78" x2="323.06" y2="1017.39" />
      <line class="cls-4" x1="450.83" y1="1024.78" x2="450.83" y2="1017.39" />
      <line class="cls-4" x1="460.16" y1="1024.78" x2="460.16" y2="1017.39" />
      <path
        class="cls-4"
        d="M437.77,1024.78v-5.93a20.48,20.48,0,0,1,20.49-20.48h34"
      />
      <path class="cls-4" d="M449.9,947.44c-1.87,9.33,1.86,37.31,18.65,50.36" />
      <path class="cls-4" d="M275.48,1024c-19.76-3.4-31.14-11.66-35.82-24" />
      <path class="cls-4" d="M573,994.5a172.23,172.23,0,0,1-6,30" />
      <path class="cls-4" d="M311.87,965.16a25.81,25.81,0,0,0,16.79,6.53" />
      <path
        class="cls-11"
        d="M418.19,991.28c-2.93-7.31-16-31.17-27.89-52.29l-.09.06c-10.88-11.79-24.42-26.22-51.74-25.29.46,6.12,5.33,70.15,9.77,90.57,3.38,15.55,14.11,20.12,19.76,21.45h43C423,1022.38,423.25,1003.93,418.19,991.28Z"
      />
      <polyline
        class="cls-16"
        points="267.1 883.72 282.45 874.34 275.63 898.22 293.68 887.49"
      />
      <polyline
        class="cls-16"
        points="296.9 837.53 313.07 836.24 297.86 852.67 316.06 851.4"
      />
      <circle class="cls-11" cx="1097.75" cy="461.28" r="7.93" />
      <path
        class="cls-1"
        d="M1212.14,488.74l1.58,8.88A45.46,45.46,0,0,0,1212.14,488.74Z"
      />
      <path class="cls-8" d="M1149.19,409.93l.06-.55h-.07Z" />
      <polygon
        class="cls-9"
        points="1072.15 409.19 1071.99 409.19 1071.97 409.38 1072.12 409.38 1072.15 409.19"
      />
      <polygon
        class="cls-9"
        points="1097.99 409.38 1098.07 409.19 1072.15 409.19 1072.12 409.38 1097.99 409.38"
      />
      <polygon
        class="cls-9"
        points="1071.97 409.38 1070.23 422.57 1070.38 422.73 1072.12 409.38 1071.97 409.38"
      />
      <polygon
        class="cls-9"
        points="1149.17 409.19 1098.21 409.19 1098.3 409.38 1149.18 409.38 1149.17 409.19"
      />
      <circle class="cls-11" cx="542.98" cy="254.6" r="45.6" />
      <path
        class="cls-8"
        d="M574.47,285c-2.79,1.28,20.63-54.29,32.57-45.61C618.29,247.58,588.58,278.49,574.47,285Z"
      />
      <path
        class="cls-8"
        d="M573.17,288.4c-2.55,3.05-62.28-17.84-54-29.9C527.09,246.9,575.72,285.35,573.17,288.4Z"
      />
      <path
        class="cls-7"
        d="M655.56,316.93c-8.67,1.46-21.37-1.52-21.37-1.52-17.38,19.54-35.84,23.88-35.84,23.88-11.94-13-33.66-13-36.91,5.43-3.61,20.43,21.71,26.06,21.71,26.06s3.59,25.54-6.18,38.57c-1.37,1.82-2.63,3.42-3.89,4.83h48.43l-1.44-8.65s15.2,5.43,28.23-2.17,16.29-21.72,11.94-43.44C657.67,347.06,657.76,331.15,655.56,316.93Z"
      />
      <path
        class="cls-13"
        d="M639.36,593.24c-21.45,12.13-32.65,35.44-32.65,35.44h74.61l-22.19-44.5C650.29,588,642,591.72,639.36,593.24Z"
      />
      <path
        class="cls-13"
        d="M535,426.52c-32.76,3.64-44.84,15.26-68.69,43.88-5.89,7.07-15,19.11-20.7,26.63l.18.15s-41.49,51.45-39.58,90.57L464.4,604l39.72-51.76,11.2,34.51s-25.18,14.92-39.17,42H606.71s11.2-23.31,32.65-35.44c2.66-1.52,10.93-5.22,19.77-9.06l-3-6,9.31-28.8L686,572.73s-14,5.87-26.86,11.45l22.19,44.5h78.35c43.83-28.91,10.25-81.13-3.73-99.79-9.63-12.83-54.1-77.41-84.87-94.19-20.52-11.19-47.74-9.63-47.74-9.63l-1.82-10.89H573.08C566.44,421.6,559.63,423.78,535,426.52Z"
      />
      <line class="cls-17" x1="685.94" y1="570.65" x2="702.78" y2="564.33" />
      <path
        class="cls-3"
        d="M533.92,579c.26-18.32,3.28-64.73,26.16-88.36,25.24-26,82.07-20.51,99.79,2.8s5.6,56,5.6,56l-8.39,28.91,24.24,50.36H464l42.9-39.17Z"
      />
      <line class="cls-18" x1="533.97" y1="578.32" x2="657.01" y2="578.32" />
      <line class="cls-18" x1="587.13" y1="539.15" x2="634.69" y2="539.15" />
      <path
        class="cls-7"
        d="M674.8,577.39l24.24,56H652.41c-9.32,0-21.45,0-20.51-4.67-7.47,5.6-19.59,4.67-18.66,0-12.12,5.6-15.85,1.87-16.78,0S607.65,611,634.69,596C650.4,587.38,674.8,577.39,674.8,577.39Z"
      />
      <path class="cls-17" d="M607.65,632.41s14-14,44.76-30.77" />
      <path class="cls-17" d="M660.81,611.9a461.33,461.33,0,0,0-45.7,32.64" />
      <path
        class="cls-17"
        d="M670.13,623.09C645,636.15,617,653.87,617,653.87"
      />
      <path
        class="cls-1"
        d="M550.25,417.37h26.11a.47.47,0,0,1,.47.47v83.41a13.53,13.53,0,0,1-13.53,13.53h0a13.53,13.53,0,0,1-13.53-13.53V417.84A.47.47,0,0,1,550.25,417.37Z"
        transform="translate(-126.78 224.58) rotate(-20.32)"
      />
      <path
        class="cls-19"
        d="M677.47,549.41l-9.31,28.8,3,6C684,578.6,698,572.73,698,572.73Z"
      />
      <path
        class="cls-1"
        d="M641.77,515h0a13.54,13.54,0,0,0,15-11.93l8.08-71.3a95.94,95.94,0,0,0-26.54-6.17L629.85,500A13.54,13.54,0,0,0,641.77,515Z"
      />
      <circle class="cls-11" cx="575.27" cy="497.99" r="7.93" />
      <circle class="cls-11" cx="643.55" cy="500.45" r="7.93" />
      <path
        class="cls-11"
        d="M490.86,428.33l47.95-14.15h34.27c1.26-1.41,2.52-3,3.89-4.83,9.77-13,6.18-38.57,6.18-38.57s-25.32-5.63-21.71-26.06c3.25-18.45,25-18.45,36.91-5.43,0,0,18.46-4.34,35.84-23.88,0,0,12.7,3,21.37,1.52-1.52-9.79-4.12-18.77-9.43-25.41-13-16.29-48.86-27.14-77.09-6.51s-26.06,54.28-25,67.31c0,0-30.4-9.77-52.11,13a55,55,0,0,0-14.12,49.95s-40-1.13-47.77,35.83c-4,18.93,2.31,32.14,10.3,40.9l5-10.18Z"
      />
      <path
        class="cls-11"
        d="M535,426.52c24.64-2.74,31.45-4.92,38.09-12.34H538.81l-47.95,14.15-45.54,53.52-5,10.18a51.81,51.81,0,0,0,5.24,5c5.66-7.52,14.81-19.56,20.7-26.63C490.15,441.78,502.23,430.16,535,426.52Z"
      />
      <path class="cls-4" d="M619.29,407.54a123.41,123.41,0,0,1-13.14-6.15" />
      <path class="cls-14" d="M476.1,416.5a99.28,99.28,0,0,0,6.36,17.38" />
      <line class="cls-14" x1="542.02" y1="353.26" x2="545.15" y2="369.7" />
      <line class="cls-17" x1="505.65" y1="553.17" x2="527.44" y2="522.36" />
      <circle class="cls-9" cx="583.15" cy="371.87" r="11.4" />
      <line class="cls-4" x1="585.32" y1="352.32" x2="576.64" y2="346.9" />
      <path class="cls-4" d="M640.87,377.81c-1.8,1.12-8.53,2-11.25-3.19" />
      <circle class="cls-11" cx="623.18" cy="350.13" r="3.26" />
      <circle class="cls-11" cx="648.3" cy="345.81" r="3.26" />
      <path
        class="cls-4"
        d="M639.61,354.5s3,7.42,2.23,8.9a5.5,5.5,0,0,1-2.23,2.23"
      />
      <path
        class="cls-8"
        d="M807.73,1014.79H789.87a27.77,27.77,0,0,1,1.54,11H866.8C868.14,1011,858.31,994.87,830,982l-.59-.28C821.64,987.55,814.66,997.2,807.73,1014.79Z"
      />
      <path
        class="cls-9"
        d="M828.66,981.36c-23.55-11.68-19.3-37.33-12.44-95.51a16.3,16.3,0,0,0-30,4.35,16.31,16.31,0,0,0-31.71,0,16.31,16.31,0,0,0-31.71,0,16.31,16.31,0,0,0-31.71,0,16.31,16.31,0,0,0-31.71,0A16.29,16.29,0,0,0,635,880.13c.1,5.15.3,10.53.62,16.22,1.52,26.93,7.6,62.95,12.82,90.15,25.44,1.36,35.75,20.14,39,28.29h42.83c.39-1,.77-1.93,1.16-2.87l.24-.57c.37-.87.73-1.72,1.1-2.55.09-.22.19-.42.28-.63.33-.74.66-1.45,1-2.15l.3-.63q.47-1,.93-1.89l.42-.85.94-1.81.54-1c.18-.34.37-.67.55-1,.32-.57.65-1.13,1-1.68.14-.22.27-.44.4-.66.75-1.23,1.5-2.39,2.26-3.48l.14-.21c.43-.61.86-1.2,1.29-1.78a46.61,46.61,0,0,1,9.22-9.4c.8.44,1.63.86,2.51,1.26,20.83,9.46,31.65,20.71,35.39,31.86h17.79c6.93-17.59,13.91-27.24,21.67-33.08Z"
      />
      <path
        class="cls-8"
        d="M789.87,1014.79h.07c-3.74-11.15-14.56-22.4-35.39-31.86-.88-.4-1.71-.82-2.51-1.26a46.09,46.09,0,0,0-8.95,9.06h0l-.18.24-.08.1h0c-.43.58-.86,1.17-1.29,1.78l-.14.21c-.76,1.09-1.51,2.25-2.26,3.48-.13.22-.26.44-.4.66-.32.55-.65,1.11-1,1.68-.18.32-.37.65-.55,1l-.54,1-.94,1.81-.42.85q-.46.91-.93,1.89l-.3.63c-.32.7-.65,1.41-1,2.15-.09.21-.19.41-.28.63-.37.83-.73,1.68-1.1,2.55l-.24.57c-.39.94-.77,1.89-1.16,2.87H687.49c-3.29-8.15-13.6-26.93-39-28.29,4.35,22.71,8.11,39.28,8.11,39.28H791.41A27.77,27.77,0,0,0,789.87,1014.79Z"
      />
      <path
        class="cls-1"
        d="M499.46,671.76v-17H467.58a107.94,107.94,0,0,0,1.84,45.7h1.3A28.74,28.74,0,0,0,499.46,671.76Z"
      />
      <path
        class="cls-7"
        d="M784.3,675.23l-1.52-.86c-10.64-.41-23.68.62-39.45,4.17l-.25.06c-1.27.28-2.56.59-3.87.91l-.73.18c-1.26.31-2.53.64-3.82,1l-.77.2c-1.41.38-2.83.79-4.28,1.21l-.44.12c-1.62.48-3.27,1-4.94,1.5-58.23,18.25-84.06,64-91.83,92.36h17.22C644.48,800,633.93,825.38,635,880.13a16.29,16.29,0,0,1,24.4,10.07,16.31,16.31,0,0,1,31.71,0,16.31,16.31,0,0,1,31.71,0,16.31,16.31,0,0,1,31.71,0,16.31,16.31,0,0,1,31.71,0,16.3,16.3,0,0,1,30-4.35c1.63-13.85,3.41-29.54,5-47.32C830.4,735.7,837.38,705.54,784.3,675.23Z"
      />
      <path
        class="cls-12"
        d="M791.46,1025.09c.5-14.31-9.62-29.76-36.91-42.16-30.89-14-17.15-49.59-8.77-143.47,9.36-104.84,16.45-134.14-40.1-165.08-49.43-27-99.79-44.76-99.79-44.76"
      />
      <path
        class="cls-1"
        d="M724.23,683.71c-60.83,19.11-84.41,64.13-91.87,92.36h0C640.17,747.73,666,702,724.23,683.71Z"
      />
      <path
        class="cls-3"
        d="M690.65,675.32a148.6,148.6,0,0,1,66.84-14.12,777,777,0,0,0-76.17-32.52H476.15s-5.6,9.33-8.49,25.62c0,.17-.05.33-.08.5h31.88v17a28.74,28.74,0,0,1-28.74,28.74h-1.3c11.17,42.84,48.48,75.57,96.31,75.57h40.78C614.8,745.41,633.28,702.26,690.65,675.32Z"
      />
      <path
        class="cls-1"
        d="M724.23,683.71c24.67-7.75,43.89-9.9,58.55-9.34l-1.66-.92c-7.92-4.33-15.87-8.43-23.63-12.25a148.6,148.6,0,0,0-66.84,14.12c-57.37,26.94-75.85,70.09-84.14,100.75h25.85C639.82,747.84,663.4,702.82,724.23,683.71Z"
      />
      <line class="cls-4" x1="651" y1="778.65" x2="673.14" y2="778.65" />
      <line class="cls-12" x1="734.17" y1="573.66" x2="734.17" y2="621.22" />
      <path
        class="cls-5"
        d="M758.23,510s-6.87,14.6-24.06,14.6-24-14.6-24-14.6"
      />
      <path
        class="cls-1"
        d="M713.35,541.95H755a0,0,0,0,1,0,0v4.35a20.83,20.83,0,0,1-20.83,20.83h0a20.83,20.83,0,0,1-20.83-20.83v-4.35A0,0,0,0,1,713.35,541.95Z"
      />
      <path
        class="cls-12"
        d="M712.75,508.37H755.6a4.69,4.69,0,0,1,4.69,4.69v33.55a26.11,26.11,0,0,1-26.11,26.11h0a26.11,26.11,0,0,1-26.11-26.11V513.06a4.69,4.69,0,0,1,4.69-4.69Z"
      />
      <polygon
        class="cls-20"
        points="734.17 616.56 713.35 628.68 755 628.68 734.17 616.56"
      />
      <line class="cls-12" x1="982.17" y1="573.66" x2="982.17" y2="621.22" />
      <path
        class="cls-5"
        d="M1006.23,510s-6.87,14.6-24.06,14.6-24-14.6-24-14.6"
      />
      <path
        class="cls-13"
        d="M961.35,541.95H1003a0,0,0,0,1,0,0v4.35a20.83,20.83,0,0,1-20.83,20.83h0a20.83,20.83,0,0,1-20.83-20.83v-4.35A0,0,0,0,1,961.35,541.95Z"
      />
      <path
        class="cls-12"
        d="M960.75,508.37h42.85a4.69,4.69,0,0,1,4.69,4.69v33.55a26.11,26.11,0,0,1-26.11,26.11h0a26.11,26.11,0,0,1-26.11-26.11V513.06a4.69,4.69,0,0,1,4.69-4.69Z"
      />
      <polygon
        class="cls-20"
        points="982.17 616.56 961.35 628.68 1003 628.68 982.17 616.56"
      />
      <circle class="cls-3" cx="1348.39" cy="988.48" r="37.31" />
      <path class="cls-17" d="M470.55,569s43.84-3.6,62.75-4.48" />
      <path
        class="cls-8"
        d="M418.49,743.8l-15-58.68H491a87.82,87.82,0,0,1,85.07,66l10.16,39.75H479.16A62.62,62.62,0,0,1,418.49,743.8Z"
      />
      <rect
        class="cls-9"
        x="1037.59"
        y="599.77"
        width="170.38"
        height="17.72"
      />
      <polygon
        class="cls-7"
        points="862.92 567.13 828.41 567.13 793.9 567.13 793.9 597.91 828.41 597.91 862.92 597.91 897.43 597.91 897.43 567.13 862.92 567.13"
      />
      <polygon
        class="cls-7"
        points="862.92 597.91 828.41 597.91 793.9 597.91 793.9 628.68 828.41 628.68 862.92 628.68 897.43 628.68 897.43 597.91 862.92 597.91"
      />
      <polygon
        class="cls-7"
        points="862.92 536.35 828.41 536.35 793.9 536.35 793.9 567.13 828.41 567.13 862.92 567.13 897.43 567.13 897.43 536.35 862.92 536.35"
      />
      <polygon
        class="cls-7"
        points="862.92 474.8 828.41 474.8 793.9 474.8 793.9 505.58 828.41 505.58 862.92 505.58 897.43 505.58 897.43 474.8 862.92 474.8"
      />
      <polygon
        class="cls-7"
        points="862.92 413.25 828.41 413.25 793.9 413.25 793.9 444.02 828.41 444.02 862.92 444.02 897.43 444.02 897.43 413.25 862.92 413.25"
      />
      <rect class="cls-7" x="828.41" y="505.58" width="34.51" height="30.78" />
      <rect class="cls-7" x="828.41" y="444.02" width="34.51" height="30.78" />
      <line class="cls-21" x1="862.92" y1="597.91" x2="862.92" y2="567.13" />
      <line class="cls-21" x1="828.41" y1="597.91" x2="828.41" y2="567.13" />
      <polygon
        class="cls-7"
        points="862.92 381.13 828.41 381.13 793.9 381.13 793.9 411.91 828.41 411.91 862.92 411.91 897.43 411.91 897.43 381.13 862.92 381.13"
      />
      <line class="cls-21" x1="862.92" y1="411.91" x2="862.92" y2="381.13" />
      <line class="cls-21" x1="828.41" y1="411.91" x2="828.41" y2="381.13" />
      <polyline
        class="cls-21"
        points="793.9 597.91 828.41 597.91 862.92 597.91 897.43 597.91"
      />
      <polyline
        class="cls-21"
        points="793.9 567.13 828.41 567.13 862.92 567.13 897.43 567.13"
      />
      <polyline
        class="cls-21"
        points="793.9 413.25 828.41 413.25 862.92 413.25 897.43 413.25"
      />
      <polyline
        class="cls-21"
        points="828.41 534.49 839.91 534.49 851.42 534.49 862.92 534.49"
      />
      <polyline
        class="cls-21"
        points="828.41 507.44 839.91 507.44 851.42 507.44 862.92 507.44"
      />
      <polyline
        class="cls-21"
        points="828.41 472.94 839.91 472.94 851.42 472.94 862.92 472.94"
      />
      <polyline
        class="cls-21"
        points="828.41 445.89 839.91 445.89 851.42 445.89 862.92 445.89"
      />
      <path
        class="cls-13"
        d="M990.56,948.58H968c-.5,12.34-3.22,21-9.67,27.23A33.18,33.18,0,0,1,948.9,982c-2.29,1-4.42,2.11-6.42,3.2,7,4.52,14,14.68,14,14.68a39.83,39.83,0,0,0,15.11-6.53c9.08-6.1,16.43-14.71,20-19.35a11.06,11.06,0,0,1,8.8-4.29l6.41,0a85.18,85.18,0,0,0,1-9C1007.75,957,1005,948.58,990.56,948.58Z"
      />
      <path
        class="cls-11"
        d="M1006.79,969.73l-6.41,0a11.06,11.06,0,0,0-8.8,4.29c-3.59,4.64-10.94,13.25-20,19.35a39.83,39.83,0,0,1-15.11,6.53s-7-10.16-14-14.68c-.74.39-1.45.79-2.15,1.19-15.15,8.74-21.58,18.63-23.5,28.41H1049a206.32,206.32,0,0,0,5.27-21.45c1.29-6.65,2.54-14.41,3.59-23.35Z"
      />
      <path
        class="cls-13"
        d="M916.35,1025.78h128.57a80.29,80.29,0,0,0,4.07-11H916.83A38.81,38.81,0,0,0,916.35,1025.78Z"
      />
      <line class="cls-18" x1="963.97" y1="966.2" x2="990.97" y2="984.2" />
      <line class="cls-18" x1="953.97" y1="977.2" x2="974.97" y2="997.2" />
      <path
        class="cls-11"
        d="M1238.58,682.13c-7.08-40.83-31.25-64.64-31.25-64.64H1143.6s-98.19,26.9-145.82,56c-55,33.58-49.47,60.24-40.11,165.08,3.34,37.39,7.53,65.52,9.41,86.73h93.7c0-.12,0-.24,0-.36h17.29s8.88-41.08-.94-77.69c-10.3-38.38-19.66-44.93-25.27-71.14h107.48C1209.16,776.07,1247.09,731.21,1238.58,682.13Z"
      />
      <path
        class="cls-1"
        d="M968,948.58h22.57c14.39,0,17.19,8.39,17.19,12.12a85.18,85.18,0,0,1-1,9l51.06.26c1.49-12.53,2.59-27.37,2.93-44.73h-93.7A188.26,188.26,0,0,1,968,948.58Z"
      />
      <line class="cls-14" x1="1050.97" y1="777.65" x2="1028.35" y2="777.65" />
      <line class="cls-18" x1="794.97" y1="928.2" x2="811.97" y2="955.2" />
      <line class="cls-18" x1="782.97" y1="934.2" x2="793.97" y2="961.2" />
      <path
        class="cls-3"
        d="M1283.48,743.8l15-58.68H1211a87.81,87.81,0,0,0-85.07,66l-10.17,39.75H1222.8A62.63,62.63,0,0,0,1283.48,743.8Z"
      />
      <rect
        class="cls-1"
        x="526.51"
        y="628.68"
        width="646.31"
        height="24.25"
        rx="12.12"
      />
      <path
        class="cls-7"
        d="M614.18,572.73c-8.65-3.93-30.78-7.46-52.23-7.46-4.08,0-8.47.07-13.06.2v60.46c10.62-3.3,19.37-6.42,25.18-8.44,0,0,42.7,32.62,50.31,17.7,0,0,11.14,4.5,14-6.51,0,0,13.06,0,8.4-9.32C643.64,613,630,598.84,630,598.84s16.2,9.77,24.25,3.73C661.74,597,624.43,577.39,614.18,572.73Z"
      />
      <path
        class="cls-13"
        d="M467.75,570.86l-60.62,2.8c-5.59,29.84,10,60.62,36.37,65.28,34.69,6.12,76.8-4.12,105.39-13V565.47C513.86,566.49,467.75,570.86,467.75,570.86Z"
      />
      <path
        class="cls-17"
        d="M631.78,597.73c-7.34-5.86-17.37-13.74-24.13-18.48"
      />
      <path
        class="cls-17"
        d="M596.46,590.31c18.33,12.76,34.82,29.6,42.65,38.07"
      />
      <path class="cls-17" d="M587.84,603.49c12.32,9,26.43,22.6,35.64,31.88" />
      <path
        class="cls-1"
        d="M971.37,590.45c-20.51,10.26-36.84,28-51.29,46.63-6.53,8.39-23,29.29-12.13,31.71,8.4,1.86,25.19-14.92,25.19-14.92s-10.11,21.89-2.8,28c5.59,4.67,13-2.79,13-2.79,6.53,14,16.79,1.86,16.79,1.86,16.46,12.14,32.64-32.64,32.64-32.64a111.82,111.82,0,0,0,27-14s5.32,1.38,13.92,3.08l-7.76-62.9C1005.64,578.23,985.45,583.41,971.37,590.45Z"
      />
      <path class="cls-12" d="M930,656.66c3-7.9,8.29-21.28,13-30.31" />
      <path
        class="cls-12"
        d="M960.18,632.41c-7.8,16.81-13.51,35.23-16.69,46.66"
      />
      <path class="cls-12" d="M977.9,638C972,651.5,966,669.77,962.23,681.92" />
      <path
        class="cls-8"
        d="M1151.37,628.68c32.33-26.93,68.2-87.11,71.81-153.88,1.87-34.51-25.18-52.23-45.69-52.23H1170l-66.21,142.7s-39.25,2.07-77.78,9.19l7.76,62.9C1061.58,642.87,1123.64,651.79,1151.37,628.68Z"
      />
      <line class="cls-10" x1="1132.1" y1="504.46" x2="1104.81" y2="563.27" />
      <path
        class="cls-10"
        d="M1178.94,598.32a233.06,233.06,0,0,0,17.15-27.84c3-5.72,5.85-11.68,8.48-17.85"
      />
      <path
        class="cls-10"
        d="M1043.49,569.7c32.49-4.91,50.48-5.5,62.48-6.5,8-.66,19-1,19-1"
      />
      <circle class="cls-11" cx="1056.71" cy="618.89" r="7.93" />
      <path
        class="cls-12"
        d="M1034.34,639.27c-8.34-1.66-13.47-3-13.47-3a111.82,111.82,0,0,1-27,14s-.56,1.56-1.59,4"
      />
      <path
        class="cls-12"
        d="M907.3,652.06a175.2,175.2,0,0,1,10.78-15q2.86-3.69,5.83-7.31"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
.cls-1 {
  fill: #a15842;
}
.cls-2 {
  fill: #ca7358;
}
.cls-3 {
  fill: #ffb7d5;
}
.cls-10,
.cls-12,
.cls-14,
.cls-15,
.cls-16,
.cls-17,
.cls-18,
.cls-19,
.cls-21,
.cls-4,
.cls-5,
.cls-6 {
  fill: none;
}
.cls-17,
.cls-21,
.cls-4 {
  stroke: #a15842;
}
.cls-10,
.cls-12,
.cls-14,
.cls-15,
.cls-16,
.cls-17,
.cls-18,
.cls-4,
.cls-5,
.cls-6 {
  stroke-linecap: round;
}
.cls-10,
.cls-14,
.cls-16,
.cls-21,
.cls-4,
.cls-5,
.cls-6 {
  stroke-linejoin: round;
}
.cls-10,
.cls-12,
.cls-14,
.cls-15,
.cls-16,
.cls-17,
.cls-18,
.cls-21,
.cls-4,
.cls-5 {
  stroke-width: 4px;
}
.cls-12,
.cls-5,
.cls-6 {
  stroke: #30242a;
}
.cls-6 {
  stroke-width: 8px;
}
.cls-7 {
  fill: #eaa58b;
}
.cls-8 {
  fill: #3ec1f3;
}
.cls-9 {
  fill: #f5ebdd;
}
.cls-10,
.cls-15 {
  stroke: #4756df;
}
.cls-11 {
  fill: #30242a;
}
.cls-12,
.cls-15,
.cls-17,
.cls-18 {
  stroke-miterlimit: 10;
}
.cls-13 {
  fill: #ff7235;
}
.cls-14 {
  stroke: #000;
}
.cls-16 {
  stroke: #ffb7d5;
}
.cls-18 {
  stroke: #f5ebdd;
}
.cls-20 {
  fill: #30232a;
}
</style>
