const userRules = {
  firstName: [
    {
      required: true,
      message: "Please input First Name",
      trigger: "blur",
    },
  ],
  lastName: [
    {
      required: true,
      message: "Please input Last Name",
      trigger: "blur",
    },
  ],
  username: [
    {
      required: true,
      message: "Please input Email",
      trigger: "blur",
    },
    {
      type: "email",
      required: true,
      message: "Please input valid email",
      trigger: "blur",
    },
  ],
  password: [
    {
      required: true,
      message: "Please enter a new password.",
    },
    {
      min: 6,
      max: 32,
      message: "Length of 6 to 12 characters",
      trigger: "blur",
    },
    { validator: validateNewPwd, trigger: "blur" },
  ],
  confirmPassword: [
    {
      required: true,
      message: "Please enter a confirmation password.",
    },
    {
      min: 6,
      max: 32,
      message: "Length of 6 to 12 characters",
      trigger: "blur",
    },
    { validator: validateComfirmPwd, trigger: "blur" },
  ],
};

const userForm = {
  firstName: "",
  lastName: "",
  middleName: "",
  username: "",
  password: "",
  confirmPassword: "",
};

const organizationRules = {
  name: [
    {
      required: true,
      message: "Please input Name",
      trigger: "blur",
    },
  ],
  addressLine1: [
    {
      required: true,
      message: "Please input Address 1",
      trigger: "blur",
    },
  ],
  city: [
    {
      required: true,
      message: "Please input City",
      trigger: "blur",
    },
  ],
  zipCode: [
    {
      required: true,
      message: "Please input Zip",
      trigger: "blur",
    },
    { type: "number", message: "Zip must be a number" },
  ],
  country: [
    {
      required: true,
      message: "Please input Country",
      trigger: "blur",
    },
  ],
  state: [
    {
      required: true,
      message: "Please input State",
      trigger: "blur",
    },
  ],
  // region: [
  //   {
  //     required: true,
  //     message: 'Please input Region',
  //     trigger: 'blur',
  //   },
  // ],
};

const organizationForm = {
  name: "",
  addressLine1: "",
  addressLine12: "",
  city: "",
  state: "",
  zipCode: "",
  country: "US",
  systemUserId: 0,
};

let reg = /(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%^&*?]{6,12}$/;

const validateNewPwd = (rule, value, callback) => {
  if (!reg.test(value)) {
    callback(
      new Error(
        "The password should be combination of numbers, letters or characters!"
      )
    );
  } else {
    callback();
  }
};

const validateComfirmPwd = (rule, value, callback) => {
  if (!reg.test(value)) {
    callback(
      new Error(
        "The password should be combination of numbers, letters or characters!"
      )
    );
  } else if (this.form.password !== value) {
    callback(
      new Error("The confirmation password is different from the new password.")
    );
  } else {
    callback();
  }
};

export { userForm, userRules, organizationForm, organizationRules };
