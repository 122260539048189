import { ref, readonly } from "vue";

import API from "@/api";
import { ElNotification } from "element-plus";

export default function useSubscriptionRepository() {
  const loading = ref(false);
  const plan = ref({});
  const currentPlan = ref({});
  const plans = ref([]);
  const payments = ref([]);
  let paymentDetails = ref([]);

  const getPaymentDetails = async () => {
    loading.value = true;
    let response = await API.Organization.Subscriptions.getPaymentDetails();

    if (response.data.Success) {
      paymentDetails.value = response.data.List;
      loading.value = false;
    } else {
      ElNotification({
        title: "Oops",
        message: "Problem while fetching payment details",
        type: "warning",
      });
    }
  };

  const updatePaymentDetails = async (data) => {
    loading.value = true;
    let response = await API.Organization.Subscriptions.updatePaymentDetails(
      data
    );

    if (response.data.Success) {
      ElNotification({
        title: "Success",
        message: "Payment details updated successfully",
        type: "success",
      });
      getPaymentDetails();
      loading.value = false;
    } else {
      ElNotification({
        title: "Oops",
        message: "Problem while updating payment details",
        type: "warning",
      });
    }
  };

  const getPlans = async () => {
    loading.value = true;
    let response = await API.Organization.Subscriptions.getPlans();

    if (response.data.Success) {
      plans.value = response.data.List;
      loading.value = false;
    } else {
      ElNotification({
        title: "Oops",
        message: "Problem while fetching subscription plans",
        type: "warning",
      });
    }
  };

  const getCurrentPlan = async () => {
    loading.value = true;
    let response = await API.Organization.Subscriptions.currentPlan();

    if (response.data.Success) {
      currentPlan.value = response.data.Item;
      loading.value = false;
    } else {
      loading.value = false;
      ElNotification({
        title: "Oops",
        message: "Problem while fetching current plan",
        type: "warning",
      });
    }
  };

  const updatePlan = async (data) => {
    loading.value = true;
    let response = await API.Organization.Subscriptions.updatePlan(data);

    if (response.data.Success) {
      ElNotification({
        title: "Success",
        message: response.data.Message,
        type: "success",
        duration: 0,
      });
      getPlans();
      getPayments();
      loading.value = false;
    } else {
      loading.value = false;
      ElNotification({
        title: "Oops",
        message: "Problem while updating subscription plan",
        type: "warning",
      });
    }
  };

  const getPayments = async () => {
    loading.value = true;
    let response = await API.Organization.Subscriptions.getInvoices();

    if (response.data.Success) {
      payments.value = response.data.List;
      loading.value = false;
    } else {
      loading.value = false;
      ElNotification({
        title: "Oops",
        message: "Problem while fetching payments",
        type: "warning",
      });
    }
  };

  const deleteCard = async (id) => {
    loading.value = true;
    let response = await API.Organization.Subscriptions.deleteCard(id);

    if (response.data.Success) {
      ElNotification({
        title: "Success",
        message: response.data.Message,
        type: "success",
        duration: 0,
      });
      getPaymentDetails();
      loading.value = false;
    } else {
      loading.value = false;
      ElNotification({
        title: "Oops",
        message: "Problem while deleting card",
        type: "warning",
      });
    }
  };

  return {
    loading: readonly(loading),
    plan: readonly(plan),
    paymentDetails: readonly(paymentDetails),
    plans: readonly(plans),
    payments: readonly(payments),
    currentPlan: readonly(currentPlan),
    getPaymentDetails,
    updatePaymentDetails,
    getPlans,
    updatePlan,
    getPayments,
    getCurrentPlan,
    deleteCard,
  };
}
