<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="384"
    height="256"
    viewBox="0 0 1600 1200"
  >
    <defs></defs>
    <title>Sylke is working</title>
    <g id="Layer_7" data-name="Layer 7">
      <rect class="cls-1" x="436.11" y="624.57" width="24.56" height="417.43" />
      <line class="cls-2" x1="460.68" y1="630.31" x2="436.11" y2="630.31" />
      <path
        class="cls-3"
        d="M324.66,958.24a36.41,36.41,0,0,1,68.76,0,36.41,36.41,0,0,1,32.37-24.37,90.7,90.7,0,0,0-11.42-16L401.15,903a30.12,30.12,0,0,1-4-34.33l11.22-20.85h0c-3.15,3.17-21.36,20.22-49,20.22s-45.85-17.05-49-20.22h-.05l11.23,20.85A30.1,30.1,0,0,1,317.43,903l-13.21,14.81a91.09,91.09,0,0,0-11.45,16A36.42,36.42,0,0,1,324.66,958.24Z"
      />
      <path
        class="cls-3"
        d="M427.8,979.31a36.41,36.41,0,0,0-34.38,24.44,36.4,36.4,0,0,0-68.76,0,36.43,36.43,0,0,0-43.48-23.29,90.57,90.57,0,0,0,26,61.54H411.37a90.57,90.57,0,0,0,26-61.4A36.22,36.22,0,0,0,427.8,979.31Z"
      />
      <path
        class="cls-4"
        d="M359.29,868.08c27.64,0,45.85-17.05,49-20.22h-98C313.44,851,331.65,868.08,359.29,868.08Z"
      />
      <path
        class="cls-5"
        d="M324.66,1003.75a36.4,36.4,0,0,1,68.76,0,36.4,36.4,0,0,1,44-23.15,90.7,90.7,0,0,0-11.61-46.73,36.41,36.41,0,0,0-32.37,24.37,36.41,36.41,0,0,0-68.76,0,36.42,36.42,0,0,0-31.89-24.34,90.68,90.68,0,0,0-11.59,46.56,36.43,36.43,0,0,1,43.48,23.29Z"
      />
      <path class="cls-6" d="M366.55,865.22c2-12.24,11.09-60.76,33.95-87.13" />
      <path class="cls-6" d="M356,865.57c-.15-13.9-1.58-82.15-15.15-122.87" />
      <ellipse
        class="cls-4"
        cx="412.51"
        cy="809.71"
        rx="39.65"
        ry="8.92"
        transform="translate(-291.13 234.65) rotate(-23.75)"
      />
      <ellipse
        class="cls-4"
        cx="409.98"
        cy="689.22"
        rx="21.94"
        ry="7.65"
        transform="translate(-387.93 608.94) rotate(-53.51)"
      />
      <ellipse
        class="cls-4"
        cx="306.39"
        cy="816.37"
        rx="47.85"
        ry="8.45"
        transform="translate(-118.67 55.01) rotate(-8.6)"
      />
      <path
        class="cls-7"
        d="M289.36,756.58l0,.07a17.69,17.69,0,0,1-14.44,32.15l0,.06c21.9,9.84,42.91,10.59,46.91,1.68S311.27,766.42,289.36,756.58Z"
      />
      <path
        class="cls-7"
        d="M354.11,685.26c-4.66-1.21-10.72,5.84-15.15,16.75-5.33-13.25-13.06-22.12-18.27-20.4s-6.14,13.44-2.53,27.26c-10.06-6.12-19.12-8.18-22.15-4.43-3.86,4.78,3.58,17.18,16.62,27.71a74.71,74.71,0,0,0,11.92,7.94,17.68,17.68,0,0,1,28.05-9.6,75,75,0,0,0,4.66-13.08C361.47,701.2,360.06,686.8,354.11,685.26Z"
      />
      <path
        class="cls-7"
        d="M455.77,758.87c-1.44-4.6-10.63-6-22.23-4,8.42-11.53,11.86-22.78,7.65-26.29s-14.66,1.9-24.48,12.27c-.12-11.78-3.17-20.56-7.95-21.15-6.09-.75-12.69,12.13-14.73,28.75a74.91,74.91,0,0,0-.44,14.33,17.68,17.68,0,0,1,23,18.73,75.87,75.87,0,0,0,13.57-3C446.13,773.54,457.6,764.73,455.77,758.87Z"
      />
      <path
        class="cls-4"
        d="M410.31,766a17.66,17.66,0,0,0-16.72-3.25c.57,7.91,2.57,14,5.58,16.21,2.81,2.6,9.31,3.47,17.4,2.52A17.65,17.65,0,0,0,410.31,766Z"
      />
      <path
        class="cls-4"
        d="M336.15,727.62a17.67,17.67,0,0,0-11.6,12.47c7,3.7,13.22,5.2,16.72,3.84,3.69-1,7.86-6.07,11.33-13.44A17.65,17.65,0,0,0,336.15,727.62Z"
      />
      <path
        class="cls-8"
        d="M291.31,786.27a8.59,8.59,0,0,1,7.05-15.6,17.67,17.67,0,0,0-9-14L274.89,788.8A17.69,17.69,0,0,0,291.31,786.27Z"
      />
      <path
        class="cls-9"
        d="M287.63,775.24a8.59,8.59,0,0,0,3.68,11,17.63,17.63,0,0,0,7.05-15.6A8.59,8.59,0,0,0,287.63,775.24Z"
      />
      <path
        class="cls-10"
        d="M948.49,791.38H694.16l4.3-17.06H810.1L852.68,607.8a105.42,105.42,0,0,1,102.13-79.3h0c57.29,0,99.21,54,85,109.5l-26.31,102.89A67.12,67.12,0,0,1,948.49,791.38Z"
      />
      <rect
        class="cls-11"
        x="768.14"
        y="876.96"
        width="18.06"
        height="162.09"
        transform="translate(-180.83 1735.17) rotate(-90)"
      />
      <path
        class="cls-11"
        d="M884.92,1032.3,842.76,781.35H711.58L669.42,1032.3a8.33,8.33,0,0,0,8.22,9.7h0a8.34,8.34,0,0,0,8.14-6.56L741,781.35h72.38l55.2,254.09a8.33,8.33,0,0,0,8.14,6.56h0A8.33,8.33,0,0,0,884.92,1032.3Z"
      />
      <rect
        class="cls-1"
        x="902.43"
        y="876.96"
        width="18.06"
        height="162.09"
        transform="translate(-46.55 1869.46) rotate(-90)"
      />
      <path
        class="cls-1"
        d="M1019.2,1032.3,977,781.35H845.87L803.71,1032.3a8.33,8.33,0,0,0,8.22,9.7h0a8.32,8.32,0,0,0,8.13-6.56l55.2-254.09h72.39l55.2,254.09A8.33,8.33,0,0,0,1011,1042h0A8.33,8.33,0,0,0,1019.2,1032.3Z"
      />
      <path
        class="cls-10"
        d="M886.71,740.89,902.79,678H796.22a94.11,94.11,0,0,0-91.17,70.78l-10.89,42.6H821.69A67.12,67.12,0,0,0,886.71,740.89Z"
      />
      <line class="cls-12" x1="701.09" y1="772.65" x2="836.39" y2="772.65" />
      <line class="cls-12" x1="810.58" y1="772.02" x2="834.29" y2="679.32" />
      <line class="cls-12" x1="1028" y1="675.85" x2="964" y2="675.85" />
      <line class="cls-12" x1="1040" y1="630.85" x2="958" y2="630.85" />
      <path
        class="cls-7"
        d="M741.31,610.53S661.08,632.38,627,649.43c-56.19,28.09-56,46.63-90.26,150.42C505.11,895.77,501.63,942.26,458,944.1c-33.31,1.4-44.23,12.3-49.63,27.24l131.81,62.54a6.55,6.55,0,0,0,8.55-2.78h0a6.55,6.55,0,0,0-2.47-8.8l-2.77-1.6c12.25-14.84,35.88-46.46,59.44-94.49l15,7S642.16,901.72,646,866c5-46.13,2-77.22,23.07-95.27l245.7-160.24Z"
      />
      <path
        class="cls-4"
        d="M445.05,951.51c2.61-5.43,17.11-38.51,25.45-34.51,10.85,5.2-17.31,38.41-17.31,38.41Z"
      />
      <path
        class="cls-4"
        d="M408.36,971.81l131.81,62.53a6.54,6.54,0,0,0,6.08-11.57l-2.77-1.6.28-.34-90.6-42.77A56.18,56.18,0,0,0,456,966.44c13.56,2.35,41,4.88,41.14-6.43.15-10.54-29.82-6.31-40.77-3.33a44.65,44.65,0,0,0-2.34-11.89C423.82,946.87,413.54,957.47,408.36,971.81Z"
      />
      <path
        class="cls-10"
        d="M627,649.43c-6.31,3.15-11.9,6.18-16.91,9.22l-7.69,24.63L632,662.43l27.29,21.76-10.06-29.83,30.91-14.16-23.89-3A297.55,297.55,0,0,0,627,649.43Z"
      />
      <polygon
        class="cls-10"
        points="714.21 636.61 712.21 649.64 725.25 644.63 714.21 636.61"
      />
      <polygon
        class="cls-10"
        points="639 701.79 619.95 726.86 592.87 713.83 608.91 740.9 583.84 761.96 615.93 757.95 626.97 795.06 638 757.95 673.1 747.92 639 733.88 639 701.79"
      />
      <path
        class="cls-10"
        d="M531.9,893.21l2.34-6.68,32-1.7-26-17.7,11.58-27.73-26.19,17.46-5-5.4c-4,13.17-7.54,24.9-11,35.21l.16,0c.38-1.13.76-2.27,1.14-3.43Z"
      />
      <path
        class="cls-13"
        d="M531.9,893.21l-21-9.93c-.38,1.16-.76,2.3-1.14,3.43-12.69,37.8-24.25,56.7-51.74,57.86-1.35,0-2.65.13-3.93.22a44.65,44.65,0,0,1,2.34,11.89c11-3,40.92-7.21,40.77,3.33-.16,11.31-27.58,8.78-41.14,6.43a56.18,56.18,0,0,1-2.87,11.62l90.6,42.77c12.32-15,35.75-46.46,59.13-94.09Z"
      />
      <path
        class="cls-10"
        d="M611.92,817.12l-3,27.08-28.08-1,24.07,18.06-9,32.09L620,872.28l21.65,14.44q.9-2.87,1.65-5.81L635,860.24l12.86-14.09c.21-2.62.41-5.2.6-7.71L626,842.19Z"
      />
      <path
        class="cls-10"
        d="M549.37,760.83c-2.53,7.82-5.25,16.31-8.25,25.54L567.8,788Z"
      />
      <path
        class="cls-4"
        d="M643.58,1005.13c.42-6,1.73-42.1,10.95-41.45,12,.84-1.95,42.08-1.95,42.08Z"
      />
      <path
        class="cls-7"
        d="M865.67,612s-100.29,18.6-155.45,41.66c-55.69,23.29-55.15,64.65-45.13,177,9,100.58,25.08,149.42-8,164.46-30.34,13.8-40.88,31.1-39.44,46.92H767a6.41,6.41,0,0,0,6.41-6.41h0a6.41,6.41,0,0,0-6.41-6.41h-2.52c4.71-15.41,11.54-45.49,12.48-95.27h18.52s9.52-44-1-83.24c-11-41.11-21.06-48.13-27.07-76.21h89.87c68.63,0,120-63.48,105.07-130.46-4.66-20.93-11.42-32-11.42-32Z"
      />
      <polygon
        class="cls-10"
        points="803.49 670.7 785.08 693.86 756.41 684.01 773.4 711.82 751.34 741.91 780.42 729.87 796.63 759.75 798.47 726.86 826.55 720.85 802.49 705.8 803.49 670.7"
      />
      <path
        class="cls-10"
        d="M763.43,849.48l17,27.81-22.06,30.09,29.08-12,10.78,19.87c1.58-15.44,2.36-38.28-2.68-60.21l-3.44,4.33Z"
      />
      <path
        class="cls-10"
        d="M688.45,933.91l-1.29-15.5,32.56-12.56-30.82-6.39,3.4-33.83-20,26.17-.6-.25c1.92,16.15,3.6,30.26,4.54,42.47v-.11Z"
      />
      <path
        class="cls-13"
        d="M767,1029.18h-2.52c4.71-15.41,11.54-45.49,12.48-95.27H676.21V934c.08,1,.15,2,.22,3,0,.09,0,.19,0,.28,2.09,31.25-1.32,49.53-19.38,57.74-30.34,13.8-40.88,31.1-39.44,46.92H767a6.41,6.41,0,0,0,0-12.82Z"
      />
      <polygon
        class="cls-10"
        points="900.72 614.59 854.65 618.67 859.45 637.19 829.98 652.91 866.69 657.82 879.11 685.33 891.88 656.84 929.22 650.94 896.79 634.24 900.72 614.59"
      />
      <path
        class="cls-10"
        d="M681.14,744.91l11-35.1,32.09,5-25.07-22.06,18-21.06-33.09,12-5.29-9.07c-8.51,9.64-13.65,21.61-16.4,37l5.64.18Z"
      />
      <path
        class="cls-10"
        d="M767,634.8c-6.77,1.88-13.51,3.85-20.09,5.88l26.51,18Z"
      />
      <polygon
        class="cls-10"
        points="723.26 797.06 709.22 767.98 702.2 800.07 676.13 798.07 700.19 819.13 685.15 854.23 711.26 829.89 732.9 854.84 726.27 817.12 748.33 788.04 723.26 797.06"
      />
      <path
        class="cls-4"
        d="M663.73,1017.45c13.26-3.66,39.14-13.08,34.46-23.37-4.36-9.6-29.66,7-38.29,14.39a44.19,44.19,0,0,0-8.25-10.78c-26.18,13.33-35.37,29.47-34,44.31H767a6.41,6.41,0,0,0,6.41-6.41h0a6.41,6.41,0,0,0-6.41-6.41H666.1A56.84,56.84,0,0,0,663.73,1017.45Z"
      />
      <line class="cls-14" x1="767" y1="776.71" x2="741.18" y2="776.35" />
      <path
        class="cls-14"
        d="M786.2,630.42c-26.75,6.82-55.34,15.19-77,24.23C663,674,655.51,705.76,660,780.24"
      />
      <rect
        class="cls-1"
        x="1101.12"
        y="624.57"
        width="24.56"
        height="417.43"
      />
      <line class="cls-2" x1="1125.68" y1="630.31" x2="1101.12" y2="630.31" />
      <path
        class="cls-11"
        d="M769.39,310.68c-14-2-46.07,13.05-47.13,42.8s25.5,39.31,29.75,41.44l61.63-9.57,4.25-68Z"
      />
      <circle class="cls-11" cx="878.27" cy="188.9" r="47.9" />
      <path
        class="cls-4"
        d="M847.2,221.84c2.92,1.35-21.67-57-34.22-47.9C801.17,182.53,832.37,215,847.2,221.84Z"
      />
      <path
        class="cls-4"
        d="M848.56,225.41c2.68,3.2,65.42-18.75,56.74-31.41C897,181.82,845.88,222.2,848.56,225.41Z"
      />
      <path
        class="cls-13"
        d="M931.85,385.89s42.12-35.1,15-74.21c-17.26-24.93-64.19-15-64.19-15,1.14-13.69,4.86-53.13-24.8-74.8s-67.29-10.26-81,6.84-10.26,49.05-14.82,71.86-1.52,38.32,12.54,45.62c12.79,6.64,27.84,2.63,27.84,2.63l-1.6,24.07s-29,1.09-46.77,7.52C711.23,395.92,636,502.22,636,502.22c-47.13-2-91.26-4-91.26-4l23.07,68.2s58.16,19.05,100.28,8c42.73-11.19,83.24-71.2,83.24-71.2l14,40.11-24.07,67.19h209.6l-35.1-62.18C921.83,493.2,931.85,385.89,931.85,385.89Z"
      />
      <path
        class="cls-3"
        d="M864.5,370.4l-55,99.73L787,373.9c-9.89,1-23.07,2.94-32.85,6.48C711.23,395.92,636,502.22,636,502.22c-47.13-2-91.26-4-91.26-4l23.07,68.2s58.16,19.05,100.28,8c42.73-11.19,83.24-71.2,83.24-71.2l14,40.11-24.07,67.19h209.6l-35.1-62.17c6-55.16,16-162.47,16-162.47a97.26,97.26,0,0,0-30.08-12C889.71,371.18,876.55,372.79,864.5,370.4Z"
      />
      <path
        class="cls-4"
        d="M579.67,603.51H753.35L713.86,478.79a18.13,18.13,0,0,0-17.29-12.67H549.79a18.13,18.13,0,0,0-17.29,23.62l32.64,103.14A15.24,15.24,0,0,0,579.67,603.51Z"
      />
      <path
        class="cls-11"
        d="M605.55,522.54c4.61-6.3,25.52,10.34,27.06,11.71.8-1.37,11.69-18,19.67-11.71,6.93,5.46-8.3,29.85-12.35,36.07a1.81,1.81,0,0,1-2.69,0C629.85,552.39,601.55,528,605.55,522.54Z"
      />
      <ellipse
        class="cls-11"
        cx="627.86"
        cy="517.12"
        rx="3.31"
        ry="10.13"
        transform="translate(-125.36 210.07) rotate(-17.3)"
      />
      <path
        class="cls-4"
        d="M854.2,589.47a10.79,10.79,0,0,1-10.28,14H579.67a15.24,15.24,0,0,1-14.53-10.63l-1.08-3.41Z"
      />
      <rect class="cls-1" x="393.79" y="603.51" width="774.21" height="25.07" />
      <path
        class="cls-10"
        d="M1013.49,740.89,1029.57,678h-93.8a94.1,94.1,0,0,0-91.16,70.78l-10.9,42.6H948.46A67.13,67.13,0,0,0,1013.49,740.89Z"
      />
      <line class="cls-12" x1="835.93" y1="774.89" x2="832.22" y2="790.57" />
      <path
        class="cls-15"
        d="M536.38,501.83c-11.67,5.83-17.5,15.55-17.5,22.36C518.88,534.13,529,536,529,536s-7.55,11.87,5.58,17.44c0,0,0,12.22,12.23,12.22,0,0-1.62,9.53,11.14,10.43s25.89-5.76,27-10.07c1.27-5.09-2.16-9.35-11.42-10.7,0,0,12.5-5.84,10.52-13.21s-10.43-7.2-10.43-7.2,8.09-4.85,3.77-12-12.58-4.67-12.58-4.67,2.16-5-1.62-8.81c-4.5-4.5-23.85,1.18-23.85,1.18Z"
      />
      <path class="cls-14" d="M546.9,566.13a145.84,145.84,0,0,0,30.46-11" />
      <path class="cls-14" d="M534.55,553.38a156,156,0,0,0,41.38-18.13" />
      <path class="cls-14" d="M528,537.42a129.2,129.2,0,0,0,37.84-18.33" />
      <path
        class="cls-11"
        d="M946.9,311.68c-17.26-24.93-64.19-15-64.19-15,1.14-13.69,4.86-53.13-24.8-74.8s-67.29-10.26-81,6.84c-5.58,7-8.31,16.42-9.9,26.7,9.1,1.53,22.45-1.6,22.45-1.6,18.25,20.53,34.6,23.79,34.6,23.79,12.77-12.77,35.17-12.35,38.81,7,3.78,20.09-28.31,29.12-28.31,29.12s-3,36.1,13,49.14a37.57,37.57,0,0,0,16.89,7.57c12,2.39,25.21.78,37.27,3.46a97.26,97.26,0,0,1,30.08,12,77,77,0,0,0,10-10.66C952.32,361.9,964.9,337.68,946.9,311.68Z"
      />
      <path class="cls-16" d="M808.5,246.5c-6,5-13,8-21.06,10" />
      <path class="cls-14" d="M802.09,351.08a74.52,74.52,0,0,0,13.41-6" />
      <line class="cls-14" x1="835.79" y1="293.56" x2="844.91" y2="287.86" />
      <path class="cls-14" d="M783.46,320.33c1.89,1.17,9,2.08,11.82-3.35" />
      <circle class="cls-4" cx="802.04" cy="291.25" r="3.42" />
      <circle class="cls-4" cx="773.69" cy="286.72" r="3.42" />
      <path
        class="cls-14"
        d="M783.32,299.93c-.68,2.15-1.27,4.49-.88,5.27a5.76,5.76,0,0,0,2.34,2.33"
      />
      <circle class="cls-8" cx="840.08" cy="315.09" r="11.98" />
      <ellipse
        class="cls-6"
        cx="765.02"
        cy="285.23"
        rx="19.65"
        ry="17.69"
        transform="translate(339 981.73) rotate(-78.99)"
      />
      <ellipse
        class="cls-6"
        cx="808.47"
        cy="290.56"
        rx="19.65"
        ry="17.69"
        transform="translate(368.92 1028.69) rotate(-78.99)"
      />
      <line class="cls-6" x1="783.27" y1="287.03" x2="790.18" y2="287.77" />
      <line class="cls-6" x1="885" y1="298" x2="883.01" y2="310.8" />
      <line class="cls-17" x1="740.31" y1="477.15" x2="749.99" y2="505.32" />
      <line class="cls-17" x1="791.45" y1="541.33" x2="766" y2="541.33" />
      <polyline
        class="cls-17"
        points="811.51 469.13 819.53 508.24 819.53 544.34"
      />
      <polyline
        class="cls-17"
        points="758.17 379.51 773.4 413.97 755.35 411.97 819.53 508.24"
      />
      <polyline
        class="cls-17"
        points="906.56 375.41 880.71 411.97 904.78 409.96 816.52 488.18"
      />
      <line class="cls-17" x1="920" y1="498" x2="900.77" y2="471.14" />
      <path class="cls-17" d="M942.25,597c-10.47-3.49-29.26-10.23-40.59-17" />
      <circle class="cls-10" cx="653.45" cy="328.5" r="19.67" />
      <rect
        class="cls-3"
        x="571.01"
        y="241.44"
        width="27.94"
        height="10.32"
        rx="5"
        transform="translate(401.35 -361.09) rotate(50.33)"
      />
      <path
        class="cls-5"
        d="M615.26,267l-11.52-13.9L396.67,424.8a8.85,8.85,0,0,0-1.16,12.46l.23.28a8.84,8.84,0,0,0,12.45,1.16L472.93,385V573.43H491V370Z"
      />
      <path class="cls-18" d="M581.89,271l5.77,7a24.76,24.76,0,0,1,4.5,8.22" />
      <circle class="cls-3" cx="481.96" cy="365.6" r="23.57" />
      <circle class="cls-5" cx="481.96" cy="365.6" r="9.06" />
      <line class="cls-2" x1="669.6" y1="317.33" x2="639.45" y2="342.25" />
      <path
        class="cls-5"
        d="M630.54,264.48a24.82,24.82,0,0,1-16.22-8.82l-8-9.67a16.7,16.7,0,0,0-23.5-2.19h0a16.68,16.68,0,0,0-2.19,23.49l8,9.7a24.81,24.81,0,0,1,5.63,17.52l-2,29.24a66.91,66.91,0,0,0,10.36,40.64l2.56,4,95.93-79.57-3.44-3.23a66.88,66.88,0,0,0-38-17.71Z"
      />
      <path
        class="cls-5"
        d="M491,573.43H472.93l-26.71,7.28a23.63,23.63,0,0,0-17.41,22.8h106.3a23.63,23.63,0,0,0-17.42-22.8Z"
      />
      <line class="cls-19" x1="682.91" y1="274.67" x2="595.12" y2="347.23" />
      <line class="cls-19" x1="472.49" y1="571.36" x2="491" y2="571.36" />
      <line class="cls-19" x1="432.32" y1="591.01" x2="531" y2="591.01" />
      <path
        class="cls-4"
        d="M1001.06,861.49c2-17,1-55.16-21.06-84.24s8-17,17-9c14.4,12.8,27.08,25.07,38.11,66.19Z"
      />
      <path
        class="cls-11"
        d="M1267.43,1042h117.73v-3.85a20.47,20.47,0,0,0-20.47-20.46h-97.87Z"
      />
      <path
        class="cls-11"
        d="M980.24,929.6l24.83,112.4h46.14v-7a17.05,17.05,0,0,0-17-17.05h0s23.06-17.29,37.1-34.34c0,0,24.07,29.33,58.17,24.31l2.47,12A27.67,27.67,0,0,0,1159,1042h111.83c49.6,0,74-7.57,75.4-35.81a8,8,0,0,0-8-8.32h-31.22a8.6,8.6,0,0,1-6.71-3.21l-13.29-16.49A32.76,32.76,0,0,0,1253.44,967l-26.73,6.83s-47.36-68.06-90.26-115.33c-41-45.13-118.12-50-148.5,8.78A92.39,92.39,0,0,0,980.24,929.6Z"
      />
      <path
        class="cls-20"
        d="M1259.8,1042v-3.85a20.46,20.46,0,0,0-20.47-20.46H1225.7"
      />
      <path
        class="cls-6"
        d="M1073.17,984.41c7-8.33,30.89-39,29.18-69-1.83-32.1-21.06-49.14-21.06-49.14"
      />
      <path class="cls-6" d="M1123.41,965.54s1.28,23.73,3.85,41.45" />
      <line class="cls-6" x1="1027.14" y1="1031.73" x2="1027.14" y2="1041" />
      <line class="cls-6" x1="1038.17" y1="1031.73" x2="1038.17" y2="1041" />
      <line class="cls-6" x1="1237.74" y1="1031.73" x2="1237.74" y2="1041" />
      <line class="cls-6" x1="1248.77" y1="1031.73" x2="1248.77" y2="1041" />
      <circle class="cls-4" cx="1278.35" cy="1000.14" r="4.51" />
      <path class="cls-4" d="M1253.74,966.91l0-.12-27.07,7,26.73-6.83Z" />
      <path
        class="cls-4"
        d="M1187.12,1018.14c-7.84,4-18.92,11.83-18,23.69h43.54l9.15-14.43a16.59,16.59,0,0,1,14-7.71h2.91l14.92-52.48-.22-.23-26.73,6.83-25.59,32.78A42.16,42.16,0,0,1,1187.12,1018.14Z"
      />
      <path
        class="cls-13"
        d="M1123.53,883s-12.11,41.92,27,57.75c19.83,8,36.3,1.93,47.73-6-17.27-23-40.12-52.28-61.82-76.2a105.15,105.15,0,0,0-18.22-15.91C1112.3,850.7,1105.82,870.64,1123.53,883Z"
      />
      <circle class="cls-13" cx="1023.63" cy="896.85" r="16.55" />
      <path
        class="cls-4"
        d="M1338.27,997.87h-6a11.56,11.56,0,0,0,11.31,13.8,11.16,11.16,0,0,0,2.1-.2,43,43,0,0,0,.59-5.28A8,8,0,0,0,1338.27,997.87Z"
      />
      <line class="cls-6" x1="1362.09" y1="1031.73" x2="1362.09" y2="1040.77" />
      <line class="cls-6" x1="1373.12" y1="1031.73" x2="1373.12" y2="1040.77" />
      <path
        class="cls-17"
        d="M1301.57,1040.8c24.52-2.47,39.14-9.11,44.45-23.24"
      />
      <path
        class="cls-15"
        d="M901.77,514.67a237.33,237.33,0,0,0-37.11-.41c-17.68,1.45-41.45,6.08-51,10.7s-46.12,20.93-36.27,26.4c9,5,27.88-2.52,27.88-2.52s-18.84,17.32-22.08,24.57,7.47,11.32,7.47,11.32c2.82,12.38,15.44,7.06,15.44,7.06C814.05,608.7,857,572.06,857,572.06a105.64,105.64,0,0,0,30.63-1.29s5.44,3.05,14.17,7.29Z"
      />
      <path
        class="cls-3"
        d="M1009.07,599.5C1089.3,561.39,973,419,931.85,385.89l-23.06,93.27,29.08,40.11c-12.74-2.2-24.92-3.71-36.1-4.6v63.39C927.23,590.44,980.73,613,1009.07,599.5Z"
      />
      <path
        class="cls-14"
        d="M801.43,549.89c7.12-5.89,18.94-15.41,27.76-21.15"
      />
      <path
        class="cls-14"
        d="M838.91,542.81c-20.49,13.27-39.16,31-48.4,40.28"
      />
      <path class="cls-14" d="M848,557.82C834,567.43,817.75,582,807.14,592" />
      <path
        class="cls-5"
        d="M1088.7,552.43a5.73,5.73,0,0,1,5.72,5.72V559a5.73,5.73,0,0,1-5.72,5.72h-29.47V552.43h29.47m0-8h-36.39a1.08,1.08,0,0,0-1.08,1.09v26.15a1.08,1.08,0,0,0,1.08,1.08h36.39A13.72,13.72,0,0,0,1102.42,559v-.88a13.72,13.72,0,0,0-13.72-13.72Z"
      />
      <path
        class="cls-5"
        d="M1044.7,540.12c-29.8,0-43.57-23.75-43.57-23.75l20,87.14h47.08l20-87.14A51.15,51.15,0,0,1,1044.7,540.12Z"
      />
      <path
        class="cls-4"
        d="M1044.7,540.12a51.15,51.15,0,0,0,43.57-23.75h-87.14S1014.9,540.12,1044.7,540.12Z"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
.cls-1 {
  fill: #ffbe21;
}
.cls-12,
.cls-14,
.cls-16,
.cls-17,
.cls-18,
.cls-19,
.cls-2,
.cls-20,
.cls-6 {
  fill: none;
  stroke-width: 4px;
}
.cls-12,
.cls-14,
.cls-2 {
  stroke: #a15842;
}
.cls-14,
.cls-16,
.cls-18,
.cls-19,
.cls-2,
.cls-20,
.cls-6 {
  stroke-miterlimit: 10;
}
.cls-3 {
  fill: #4756df;
}
.cls-4 {
  fill: #30242a;
}
.cls-5 {
  fill: #3ec1f3;
}
.cls-17,
.cls-20,
.cls-6 {
  stroke: #30242a;
}
.cls-12,
.cls-14,
.cls-16,
.cls-17,
.cls-19,
.cls-6 {
  stroke-linecap: round;
}
.cls-7 {
  fill: #ff7235;
}
.cls-8 {
  fill: #f5ebdd;
}
.cls-9 {
  fill: #4856df;
}
.cls-10 {
  fill: #ffb7d5;
}
.cls-11 {
  fill: #a15842;
}
.cls-12,
.cls-17 {
  stroke-linejoin: round;
}
.cls-13 {
  fill: #eaa58b;
}
.cls-15 {
  fill: #eaa58a;
}
.cls-16 {
  stroke: #eaa58b;
}
.cls-18,
.cls-19 {
  stroke: #4756df;
}
</style>
