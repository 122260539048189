<template>
  <div class="cover">
    <div
      class="flex min-h-screen flex-col justify-center bg-white py-12 sm:px-6 lg:px-8"
    >
      <div class="bg-white-50 flex justify-center">
        <div class="steps">
          <transition name="component-fade" mode="out-in">
            <component :is="steps" :currentStep="currentStep"></component>
          </transition>
          <div class="mt-4 flex justify-center">
            <transition name="component-fade" mode="out-in">
              <component :is="illustration"></component>
            </transition>
          </div>
        </div>
        <div class="forms">
          <transition name="component-fade" mode="out-in">
            <keep-alive>
              <component
                :is="view"
                :userId="userId"
                :loginInfo="loginInfo"
                @onNextStep="next"
                @onPreviousStep="previous"
              ></component>
            </keep-alive>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { shallowRef, ref, onMounted } from "vue";

/* wizard */
import User from "./wizard/_user.vue";
import Organization from "./wizard/_organization";
import Payment from "./wizard/_payment";

/* Steps */
import Steps from "./steps";

import IllustrationOne from "@/common/components/svg/IllustrationOne";
import IllustrationTwo from "@/common/components/svg/IllustrationTwo";
import IllustrationThree from "@/common/components/svg/IllustrationThree";

export default {
  components: {
    Steps,
    User,
    Payment,
    Organization,
    IllustrationOne,
    IllustrationTwo,
    IllustrationThree,
  },
  setup() {
    // set User component as first component
    const view = shallowRef(User);
    const illustration = shallowRef(IllustrationOne);
    const steps = shallowRef(Steps);
    const currentStep = ref(0);
    let userId = ref(0);
    let loginInfo = ref(0);

    const component = {
      USER: 0,
      ORGANIZATION: 1,
      PAYMENT: 2,
    };

    onMounted(() => {
      //store.dispatch("wizard/removeToken");
      //store.dispatch("wizard/removeUser");
    });

    const next = (nextStep) => {
      if (nextStep.id === component.ORGANIZATION) {
        userId.value = nextStep.data.Id;
        loginInfo.value = nextStep.loginInfo;
      }

      view.value = nextStep.component;
      currentStep.value = nextStep.id;
      illustration.value = nextStep.illustration;
    };

    const previous = (step) => {
      view.value = step.component;
      currentStep.value = step.id;
    };

    return {
      view,
      next,
      steps,
      userId,
      loginInfo,
      previous,
      currentStep,
      illustration,
    };
  },
};
</script>

<style scoped>
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}

.component-fade-enter-from,
.component-fade-leave-to {
  opacity: 0;
}

.centered {
  position: absolute;
  top: 30%;
  left: 30%;
  transform: translate(-15%, -30%);
  width: 100%;
}

.steps {
  display: flex;
  flex-direction: column;
  /* border: 1px solid rgba(249, 250, 251, 1);
  border-radius: 0.375rem; */
  margin-right: 3rem;
  padding: 4rem;
}

.forms {
  width: 40%;
  padding: 4rem 2rem;
}
</style>
