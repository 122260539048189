<template>
  <div class="step" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form id="subscription-form" class="stripe-form">
      <el-form-item>
        <div ref="cardElement"></div>
      </el-form-item>
      <div
        id="card-element-errors"
        class="rounded-md pb-2 text-left text-red-400"
        role="alert"
      ></div>
    </el-form>
    <div class="sm:mt-3 sm:mb-6">
      <RadioGroup v-model="selected">
        <RadioGroupLabel class="sr-only">Plans</RadioGroupLabel>
        <div class="space-y-2">
          <RadioGroupOption
            as="template"
            v-for="plan in formattedPlans"
            :key="plan.name"
            :value="plan"
            v-slot="{ active, checked }"
          >
            <div
              :class="[
                active
                  ? 'ring-offset-sky-300 ring-2 ring-white ring-opacity-60 ring-offset-2'
                  : '',
                checked
                  ? 'bg-sky-900 border-2 border-purple-400 bg-opacity-75 text-gray-900'
                  : 'border-2 bg-white',
              ]"
              class="focus:outline-none relative flex cursor-pointer rounded-lg border px-5 py-4"
            >
              <div class="flex w-full items-center justify-between text-left">
                <div class="flex items-center">
                  <div class="text-sm">
                    <RadioGroupLabel
                      as="p"
                      :class="checked ? 'text-gray-900' : 'text-gray-900'"
                      class="mb-2 font-medium text-purple-500"
                    >
                      <div class="flex">
                        <span>{{ plan.name }}</span>
                        <InformationCircleIcon
                          v-tippy="{
                            content: plan.description,
                            animation: 'scale',
                            animateFill: false,
                            theme: 'tomato',
                          }"
                          class="ml-5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </RadioGroupLabel>
                    <RadioGroupDescription
                      as="span"
                      :class="checked ? 'text-sky-100' : 'text-gray-500'"
                      class="inline"
                    >
                      <div v-if="plan.tiersMonthly === null">
                        <span
                          v-if="!annualBilling"
                          :class="[
                            checked ? 'text-gray-900' : 'text-gray-900',
                            'font-normal',
                          ]"
                          >${{ plan.priceMonthly }} / mo</span
                        >
                        <span
                          v-else
                          :class="checked ? 'text-gray-900' : 'text-gray-900'"
                          >${{ plan.priceYearly }} / yr</span
                        >
                      </div>
                      <div v-else>
                        <div v-if="!annualBilling">
                          <div
                            v-for="tier in plan.tiersMonthly"
                            :key="tier"
                            :class="[
                              checked ? 'text-gray-900' : 'text-gray-900',
                              'font-normal',
                            ]"
                          >
                            ${{ tier.flat_amount_decimal / 100 }} {{ " / " }}
                            {{ tier.up_to !== null ? tier.up_to : "Unlimited" }}
                            users / mo
                          </div>
                        </div>
                        <div v-else>
                          <div
                            v-for="tier in plan.tiersYearly"
                            :key="tier"
                            :class="[
                              checked ? 'text-gray-900' : 'text-gray-900',
                              'font-normal',
                            ]"
                          >
                            ${{ tier.flat_amount_decimal / 100 }} {{ " / " }}
                            {{ tier.up_to !== null ? tier.up_to : "Unlimited" }}
                            users / yr
                          </div>
                        </div>
                      </div>
                    </RadioGroupDescription>
                  </div>
                </div>
                <div v-show="checked" class="flex-shrink-0">
                  <CheckCircleIcon class="h-6 w-6 text-purple-500" />
                </div>
              </div>
            </div>
          </RadioGroupOption>
        </div>
      </RadioGroup>
      <SwitchGroup
        as="div"
        class="mt-4 flex items-center"
        v-if="formattedPlans.length > 0"
      >
        <Switch
          v-model="annualBilling"
          :class="[
            annualBilling ? 'bg-purple-500' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',
          ]"
        >
          <span class="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            :class="[
              annualBilling ? 'translate-x-5' : 'translate-x-0',
              'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
            ]"
          />
        </Switch>
        <SwitchLabel as="span" class="ml-3">
          <span class="text-sm font-medium text-gray-900">Annual billing </span>
          <span class="text-sm text-gray-500">(Save {{ percentage }}%)</span>
        </SwitchLabel>
      </SwitchGroup>
    </div>

    <div class="flex justify-between">
      <button
        type="button"
        @click="previous"
        class="focus:outline-none rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Go Back
      </button>
      <el-button
        type="button"
        @click="createPaymentMethod"
        :loading="loading || buttonLoading"
        class="focus:outline-none ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Finish
      </el-button>
    </div>
  </div>
</template>

<script>
import { ref, toRefs, onMounted, computed } from "vue";
import { useStore } from "vuex";
import useSubscriptionRepository from "@/composable/useSubscriptionRepository";

import {
  RadioGroup,
  RadioGroupLabel,
  RadioGroupDescription,
  RadioGroupOption,
  Switch,
  SwitchGroup,
  SwitchLabel,
} from "@headlessui/vue";
import { ElNotification } from "element-plus";
import { CheckCircleIcon, InformationCircleIcon } from "@heroicons/vue/outline";

import { directive } from "vue-tippy";
import "tippy.js/animations/scale.css";

import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_API_LOCATION;

let stripe = window.Stripe(process.env.VUE_APP_STRIPE_PK),
  elements = stripe.elements(),
  card = undefined;

let style = {
  base: {
    padding: "4px",
    fontWeight: 300,
    fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
    fontSize: "18px",
    fontSmoothing: "antialiased",
  },
  invalid: {
    iconColor: "rgb(220, 38, 38)",
    color: "rgb(220, 38, 38)",
  },
};

export default {
  name: "PaymentInwizard",
  components: {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupDescription,
    RadioGroupOption,
    Switch,
    SwitchGroup,
    SwitchLabel,
    CheckCircleIcon,
    InformationCircleIcon,
  },
  directives: {
    tippy: directive,
  },
  props: {
    userId: {
      type: Number,
    },
    loginInfo: {},
  },
  setup(_, context) {
    const { loading, plans, getPlans } = useSubscriptionRepository();
    const store = useStore();
    const plansToFormat = ref(plans);
    const selected = ref({});
    const annualBilling = ref(false);

    const buttonLoading = ref(false);

    let cardElement = ref(null);
    let user = toRefs({});

    let headers = toRefs({});

    const formattedPlans = computed(() => {
      let newPlans = [];

      if (plansToFormat.value.length > 0) {
        const activePlans = plansToFormat.value.filter(
          (plan) => plan.product.active === true
        );

        const standardPlans = activePlans.filter(
          (plan) => plan.product.name === "Standard Plan"
        );

        const enterprisePlans = activePlans.filter(
          (plan) => plan.product.name === "Enterprise Plan"
        );

        const standardYearlySubscription = standardPlans.find(
          (plan) => plan.nickname === "Standard Yearly Subscription"
        );

        const standardMonthlySubscription = standardPlans.find(
          (plan) => plan.nickname === "Standard Monthly Subscription"
        );

        const enterpriseMonthlySubscription = enterprisePlans.find(
          (plan) => plan.nickname === "Enterprise Monthly Subscription"
        );

        const enterpriseYearlySubscription = enterprisePlans.find(
          (plan) => plan.nickname === "Enterprise Yearly Subscription"
        );

        newPlans.push({
          idMonthly: standardMonthlySubscription.id,
          idYearly: standardYearlySubscription.id,
          name: standardMonthlySubscription.product.name,
          priceMonthly: standardMonthlySubscription.amount / 100,
          priceYearly: standardYearlySubscription.amount / 100,
          tiersMonthly: standardMonthlySubscription.tiers,
          tiersYearly: standardYearlySubscription.tiers,
          description: standardMonthlySubscription.product.description,
        });

        newPlans.push({
          idMonthly: enterpriseMonthlySubscription.id,
          idYearly: enterpriseYearlySubscription.id,
          name: enterpriseMonthlySubscription.product.name,
          priceMonthly: enterpriseMonthlySubscription.amount / 100,
          priceYearly: enterpriseYearlySubscription.amount / 100,
          tiersMonthly: enterpriseMonthlySubscription.tiers,
          tiersYearly: enterpriseYearlySubscription.tiers,
          description: enterpriseMonthlySubscription.product.description,
        });
      }

      return newPlans;
    });

    const percentage = computed(() => {
      if (formattedPlans.value.length === 0) return 0;
      return (
        (formattedPlans.value[0].priceMonthly /
          formattedPlans.value[0].priceYearly) *
        100
      );
    });

    onMounted(() => {
      getPlans();

      card = elements.create("card", { style: style });
      card.mount(cardElement.value);

      card.on("change", function (event) {
        displayError(event);
      });

      user.value = store.state.wizard.user;

      headers.value = {
        headers: {
          Authorization: `Bearer ${store.state.wizard.token}`,
        },
      };
    });

    const previous = () => {
      context.emit("onPreviousStep", {
        component: "Organization",
        id: 1,
      });
    };

    const displayError = (event) => {
      loading.value = false;

      let displayError = document.getElementById("card-element-errors");
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = "";
      }
    };

    return {
      user,
      selected,
      formattedPlans,
      headers,
      previous,
      cardElement,
      displayError,
      loading,
      buttonLoading,
      annualBilling,
      percentage,
    };
  },
  methods: {
    createPaymentMethod() {
      this.buttonLoading = true;
      if (Object.keys(this.selected).length === 0) {
        ElNotification({
          message: "Please select a plan",
          type: "info",
        });
        this.buttonLoading = false;
        return;
      }

      this.buttonLoading = false;
      this.fullscreenLoading = true;

      axios
        .get(`Subscriptions/subscription-plan`, this.headers.value)
        .then(() => {
          let priceId = this.annualBilling.value
            ? this.selected.idYearly
            : this.selected.idMonthly; // planResult.data.Item.PriceId;
          let billingName = this.user.fullName;

          let data = {
            emailAddress: this.$store.state.wizard.user.Username,
            phoneNumber: null,
            fullName: this.$store.state.wizard.user.FullName,
          };

          axios
            .post(`Subscriptions/create-customer`, data, this.headers.value)
            .then((customerResult) => {
              const customerId = customerResult.data.Item.id;

              stripe
                .createPaymentMethod({
                  type: "card",
                  card: card,
                  billing_details: {
                    name: billingName,
                  },
                })
                .then((result) => {
                  if (result.error) {
                    this.displayError(result);
                  } else {
                    this.createSubscription({
                      customerId: customerId,
                      paymentMethodId: result.paymentMethod.id,
                      priceId: priceId,
                    });
                  }
                });
            });
        });
    },

    createSubscription({ customerId, paymentMethodId, priceId }) {
      let data = {
        customerId: customerId,
        paymentMethodId: paymentMethodId,
        priceId: priceId,
      };

      return (
        axios
          .post(`Subscriptions/create-subscription`, data, this.headers.value)
          //API.Organization.Subscriptions.createSubscription(data)
          .then((response) => {
            return JSON.stringify(response.data.Item);
          })
          // If the card is declined, display an error to the user.
          .then((result) => {
            if (result.error) {
              // The card had an error when trying to attach it to a customer.
              throw result;
            }
            return result;
          })
          // Normalize the result to contain the object returned by Stripe.
          // Add the additional details we need.
          .then((result) => {
            return {
              paymentMethodId: paymentMethodId,
              priceId: priceId,
              subscription: result,
            };
          })
          // Some payment methods require a customer to be on session
          // to complete the payment process. Check the status of the
          // payment intent to handle these actions.
          //.then(this.handlePaymentThatRequiresCustomerAction)
          // If attaching this card to a Customer object succeeds,
          // but attempts to charge the customer fail, you
          // get a requires_payment_method error.
          //.then(this.handleRequiresPaymentMethod)
          // No more actions required. Provision your service for the user.
          .then(this.onSubscriptionComplete)
          .catch((error) => {
            console.log("err", error);
            // An error has happened. Display the failure to the user here.
            // We utilize the HTML element we created.
            this.showCardError(error);
          })
      );
    },

    onSubscriptionComplete() {
      const target = {
        token: this.$store.state.wizard.token,
        refreshToken: this.$store.state.wizard.refreshToken,
        user: this.$store.state.wizard.user,
      };
      const handler = {};
      const proxy = new Proxy(target, handler);

      Promise.all([
        this.$store.dispatch("auth/setToken", proxy.token),
        this.$store.dispatch("user/setUser", proxy.user),
        this.$store.dispatch("auth/setIsAuthenticated", true),
        this.$store.dispatch("auth/setRefreshToken", proxy.refreshToken),
        this.$store.dispatch("wizard/removeToken"),
        this.$store.dispatch("wizard/removeRefreshToken"),
        this.$store.dispatch("wizard/removeUser"),
        this.$store.dispatch("wizard/removeOrganization"),
      ]).finally(() => {
        this.fullscreenLoading = false;
        this.$router.push("/organization/connections");
      });
    },

    showCardError(error) {
      console.log({ error });
    },
  },
};
</script>

<style scoped>
.StripeElement {
  border: 1px solid #eee;
  padding: 8px 12px;
  border-radius: 6px;
}

.StripeElement--invalid {
  border: 1px solid red;
}
</style>
