import axios from "axios";
import qs from "qs";

axios.defaults.baseURL = process.env.VUE_APP_API_LOCATION;

const setTokenFromUser = async (user) => {
  const tokenUrl =
    process.env.VUE_APP_API_LOCATION.replace("api/", "") + `connect/token`;

  const headers = {
    accept: "*/*",
    "content-type": "application/x-www-form-urlencoded",
  };

  const data = {
    client_id: process.env.VUE_APP_CLIENT_ID,
    client_secret: process.env.VUE_APP_CLIENT_SECRET,
    grant_type: process.env.VUE_APP_GRANT_TYPE,
    scope: "offline_access",
    username: user.username,
    password: user.password,
  };

  return await axios.post(tokenUrl, qs.stringify(data), headers);
};

export { setTokenFromUser };
